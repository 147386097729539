import styled, { css } from 'styled-components';
import { GapSizeType } from '../../../theme/type/gap-type';
import { AllocationsProgressBarVariantType } from '../../../theme/type/allocations-progress-bar-type';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['gap'].includes(prop),
})<{
    gap: GapSizeType;
}>(
    ({ theme, gap }) => css`
        display: flex;
        align-items: center;
        position: relative;
        border-radius: ${theme.allocationsProgressBar.base.radius};
        margin: 0 0 ${theme.gap[gap]};
        width: 100%;
    `,
);

export const Inner = styled.div.withConfig({
    shouldForwardProp: (prop) => !['empty'].includes(prop),
})<{
    empty: boolean;
}>(
    ({ theme, empty }) => css`
        display: flex;
        align-items: center;
        position: relative;
        border-radius: ${theme.allocationsProgressBar.base.radius};
        padding: 0.3rem;
        width: 100%;

        ${!empty &&
        css`
            padding: ${theme.allocationsProgressBar.base.padding};
        `}
    `,
);

export const Label = styled.div.withConfig({
    shouldForwardProp: (prop) => !['showFullBar'].includes(prop),
})<{
    showFullBar: boolean;
}>(
    ({ showFullBar }) => css`
        width: auto;
        z-index: 1;

        ${showFullBar &&
        css`
            width: 100%;
        `};
    `,
);

export const Bar = styled.div.withConfig({
    shouldForwardProp: (prop) => !['value', 'variant'].includes(prop),
})<{
    value: number;
    variant?: AllocationsProgressBarVariantType;
}>(
    ({ theme, value, variant }) => css`
        position: absolute;
        left: 0;
        width: ${value}%;
        border-radius: ${theme.radius.large};
        height: 100%;
        max-width: 100%;

        ${variant &&
        css`
            background-color: ${theme.allocationsProgressBar.variant[variant].bar};
        `}
    `,
);
