import React from 'react';
import { Wrapper, Inner, Bar, Label } from './allocations-progress-bar.styled';
import { GapSizeType } from '../../../theme/type/gap-type';
import { AllocationsProgressBarVariantType } from '../../../theme/type/allocations-progress-bar-type';

type AllocationsProgressBarProps = {
    value: number;
    variant?: AllocationsProgressBarVariantType;
    background?: AllocationsProgressBarVariantType;
    children?: React.ReactChild;
    showFullBar?: boolean;
    twoLabels?: boolean;
    gap?: GapSizeType;
};

export const AllocationsProgressBar: React.FC<AllocationsProgressBarProps> = ({
    value = 0,
    children,
    variant = 'primary',
    showFullBar = false,
    gap = 'none',
}: AllocationsProgressBarProps) => {
    return (
        <Wrapper gap={gap}>
            <Inner empty={!children}>
                <Bar value={value} variant={variant} />
                <Label showFullBar={showFullBar}>{children && children}</Label>
            </Inner>
        </Wrapper>
    );
};
