import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Wrapper } from './card-dashboard-all-time.styled';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { CardUpdated } from '../card-loading/card-updated';
import { ValueAmount } from '../../../value/amount/value-amount';
import { CardTitle } from '../../title/card-title';
import { ValueDescription } from '../../value-description/value-description';

export const CardDashboardAllTime: React.FC = observer(() => {
    const { t } = useTranslation();

    const rootStore = useRootStore();
    const { portfolioStore, statisticStore } = rootStore;
    const { overviewStore } = statisticStore;

    const { overview } = overviewStore;

    const portfolioCurrency = portfolioStore.selectedPortfolio.portfolioHistoryCurrencyCode;

    return (
        <CardUpdated fields={['totalPortfolioValue']}>
            <Wrapper>
                <div>
                    <CardTitle
                        title={t('layout.statisticsCard.allTime.title')}
                        tooltip={t('layout.statisticsCard.allTime.tooltip')}
                    />
                </div>
                {overview && (
                    <ValueDescription
                        value={
                            <ValueAmount
                                amount={overview.totalPortfolioValue}
                                currency={portfolioCurrency}
                                mode="pure"
                                hidable
                            />
                        }
                        padding={'none'}
                        valueColor={'secondary'}
                    />
                )}
            </Wrapper>
        </CardUpdated>
    );
});
