import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { ColorEnum } from '@app/common';
import {
    IconTitleWrapper,
    Title,
    TitleLabelWrapper,
    TitleWrapper,
    Wrapper,
} from './skeleton-dashboard-cards-portfolio-value.styled';
import { ValueDescription } from '../../../../card/value-description/value-description';
import Skeleton from '../../../skeleton';
import { CardTitle } from '../../../../card/title/card-title';

export const SkeletonDashboardCardsPortfolioValue: React.FC = observer(() => {
    const { t } = useTranslation();

    return (
        <Wrapper variant={ColorEnum.Gray}>
            <TitleLabelWrapper>
                <CardTitle
                    title={
                        <TitleWrapper>
                            <Title>{t('layout.statisticsCard.portfolioValue.title')}</Title>
                        </TitleWrapper>
                    }
                />
                <IconTitleWrapper>
                    <Skeleton width={3} height={3} radius={20} />
                    <Skeleton height={1.6} />
                </IconTitleWrapper>
            </TitleLabelWrapper>
            <ValueDescription
                value={<Skeleton height={2.4} />}
                description={<Skeleton height={1.3} width={11} />}
                valueColor={'default'}
                descriptionColor={'default'}
            />
        </Wrapper>
    );
});
