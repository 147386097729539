import React from 'react';
import { Row } from './grid.styled';
import { GapSizeType } from '../../../theme/type/gap-type';

interface GridPropsInterface {
    children: Array<JSX.Element>;
    cols?: number | object | string;
    gap?: number;
    marginBottom?: GapSizeType;
}

export const Grid: React.FC<GridPropsInterface> = ({ cols, children, gap = 1.5, marginBottom = 'none' }) => {
    return (
        <Row cols={cols || children.length} gap={gap} marginBottom={marginBottom}>
            {children}
        </Row>
    );
};
