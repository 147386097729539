import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { Wrapper } from './card-dashboard-unrealized-profit.styled';
import { ValueDescription } from '../../value-description/value-description';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { routes } from '../../../../../config/routes';
import { CardUpdated } from '../card-loading/card-updated';
import { IconArrowRightWide } from '../../../icons/arrow-right-wide';
import { IconButton } from '../../../icon/button/icon-button';
import { ValueAmount } from '../../../value/amount/value-amount';
import { ValuePercents } from '../../../value/percents/value-precents';
import { CardTitle } from '../../title/card-title';
import { Badge } from '../../../badge/badge';
import { Stack } from '../../../stack/stack';

export const CardDashboardUnrealizedProfit: React.FC = observer(() => {
    const { t } = useTranslation();
    const router = useRouter();

    const rootStore = useRootStore();
    const { portfolioStore, statisticStore } = rootStore;
    const { overviewStore } = statisticStore;
    const { overview } = overviewStore;

    const portfolioCurrency = portfolioStore.selectedPortfolio.portfolioHistoryCurrencyCode;

    return (
        <CardUpdated fields={['unrealizedProfit', 'unrealizedProfitPercentage']}>
            <Wrapper>
                <CardTitle
                    title={t('layout.statisticsCard.unrealizedProfit.title')}
                    tooltip={t('layout.statisticsCard.unrealizedProfit.tooltip')}
                    actions={
                        <IconButton
                            icon={<IconArrowRightWide />}
                            variation={'outlined'}
                            size={'small'}
                            onClick={async () => {
                                await router.push(`${routes.app.profits}`);
                            }}
                        />
                    }
                />
                {overview && (
                    <Stack direction="vertical">
                        <Badge variant={overview.todayUnrealizedProfit > 0 ? 'positive' : 'negative'}>
                            <Trans
                                i18nKey="layout.statisticsCard.unrealizedProfit.todayProfit"
                                components={{
                                    value: (
                                        <ValueAmount
                                            amount={overview.todayUnrealizedProfit}
                                            currency={portfolioCurrency}
                                            mode="pure"
                                            hidable
                                        />
                                    ),
                                }}
                            />
                        </Badge>
                        <ValueDescription
                            padding={'none'}
                            value={
                                <ValueAmount
                                    amount={overview.unrealizedProfit}
                                    currency={portfolioCurrency}
                                    mode="pure"
                                    hidable
                                />
                            }
                            description={
                                <Trans
                                    i18nKey="layout.statisticsCard.unrealizedProfit.ofPortfolioValue"
                                    components={{
                                        value: (
                                            <ValuePercents amount={overview.unrealizedProfitPercentage} mode="pure" />
                                        ),
                                    }}
                                />
                            }
                            valueColor={overview.unrealizedProfit > 0 ? 'positive' : 'negative'}
                        />
                    </Stack>
                )}
            </Wrapper>
        </CardUpdated>
    );
});
