import * as React from 'react';
import { Description, Value, Wrapper } from './value-description.styled';
import { GapSizeType } from '../../../../theme/type/gap-type';
import { TextVariantType } from '../../../../theme/type/text-type';

interface ValueDescriptionInterface {
    description?: string | React.ReactNode;
    value?: React.ReactNode;
    valueColor?: TextVariantType;
    descriptionColor?: TextVariantType;
    padding?: GapSizeType;
}

export const ValueDescription: React.FC<ValueDescriptionInterface> = ({
    value,
    description,
    valueColor,
    descriptionColor,
    padding,
}) => {
    return (
        <Wrapper padding={padding}>
            {value && <Value color={valueColor}>{value}</Value>}
            {description && <Description color={descriptionColor}>{description}</Description>}
        </Wrapper>
    );
};
