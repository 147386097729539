import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ResponsivePie } from '@nivo/pie';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { formatDecimal } from '@app/common';
import { Wrapper, Chart, ChartInner, Legend, LegendInner, LegendWrapper } from './base-pie-chart.styled';
import { BaseLegend } from '../legend/base-legend';
import { PieChartTypeEnum } from '../../../../../models/enums/pie-chart-type-enum';
import { Tooltip } from './tooltip/tooltip';
import { PieChartDataMobxDto } from '../../../../../models/mobx/dtos/statistic/pie-chart-data-mobx-dto';

export interface BasePieChartProps {
    data: PieChartDataMobxDto[];
    type?: PieChartTypeEnum;
    currency?: string | null;
    orientation?: 'horizontal' | 'vertical';
    isFree?: boolean;
    isFullScreen?: boolean;
}

export const BasePieChart: React.FC<BasePieChartProps> = observer((props: BasePieChartProps) => {
    const { data, type, currency, orientation = 'horizontal', isFree = false, isFullScreen } = props;
    const [isLegendScrolledTop, setIsLegendScrolledTop] = useState(true);
    const [isLegendScrolledBottom, setIsLegendScrolledBottom] = useState(true);
    const legendRef = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const colors = [
        '#3347FF',
        '#FFA733',
        '#865EF8',
        '#FF697B',
        '#15BE96',
        '#8E91AF',
        '#46DEFF',
        '#FFCA85',
        '#AD90FF',
        '#FFB0B9',
        '#72E7CB',
        '#BABDDE',
        '#2930CE',
        '#B37524',
        '#6337E1',
        '#FF3A52',
        '#009B76',
        '#73758E',

        '#484191',
        '#91417B',
        '#6F2B2B',
        '#639641',
        '#459B90',
        '#BE443C',
        '#7B72D5',
        '#BC67A4',
        '#8C4141',
        '#8DBA6F',
        '#77B8B0',
        '#C1625B',
        '#231D61',
        '#7A2E65',
        '#572020',
        '#49742D',
        '#2E7F74',
        '#A82A22',
    ];

    const translated: PieChartDataMobxDto[] = data.map((d) => ({
        ...d,
        id: i18n.exists(`pages.index.${type}.${d.id.replace(/ /g, '')}`)
            ? t(`pages.index.${type}.${d.id.replace(/ /g, '')}`)
            : d.id,
        name: i18n.exists(`pages.index.${type}.${d.name.replace(/ /g, '')}`)
            ? t(`pages.index.${type}.${d.name.replace(/ /g, '')}`)
            : d.name,
    }));

    const handleLegendScroll = () => {
        if (legendRef.current) {
            const { scrollTop } = legendRef.current;
            const { scrollHeight } = legendRef.current;
            const { clientHeight } = legendRef.current;

            if (scrollTop === 0) {
                setIsLegendScrolledTop(true);
            } else {
                setIsLegendScrolledTop(false);
            }

            if (scrollTop + clientHeight === scrollHeight) {
                setIsLegendScrolledBottom(true);
            } else {
                setIsLegendScrolledBottom(false);
            }
        }
    };

    useEffect(() => {
        if (legendRef.current) {
            const { scrollHeight } = legendRef.current;
            const { clientHeight } = legendRef.current;

            if (scrollHeight > clientHeight) {
                setIsLegendScrolledBottom(false);
            }
        }
    }, []);

    return (
        <Wrapper isFullScreen={isFullScreen} orientation={isFullScreen ? 'horizontal' : orientation}>
            <Chart orientation={isFullScreen ? 'horizontal' : orientation} isFree={isFree} isFullScreen={isFullScreen}>
                <ChartInner isFullScreen={isFullScreen}>
                    <ResponsivePie
                        theme={{
                            legends: { text: { fontSize: theme.text.size.medium.fontSize } },
                            tooltip: { basic: { fontFamily: theme.base.fontFace } },
                        }}
                        data={translated}
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        innerRadius={0.5}
                        padAngle={1}
                        tooltip={(datum) => {
                            return isFree ? null : <Tooltip type={props.type} currency={currency} data={datum} />;
                        }}
                        cornerRadius={6}
                        activeInnerRadiusOffset={isFree ? 0 : 6}
                        activeOuterRadiusOffset={isFree ? 0 : 6}
                        borderWidth={0}
                        enableArcLabels={false}
                        enableArcLinkLabels={false}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor={theme.palette.color.gray.main}
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                        arcLinkLabel={(value) => `${value.id}`}
                        valueFormat={(value) => `${formatDecimal(value, 1)}%`}
                        colors={colors}
                    />
                </ChartInner>
            </Chart>
            <LegendWrapper
                isFullScreen={isFullScreen}
                isLegendScrolledTop={isLegendScrolledTop}
                isLegendScrolledBottom={isLegendScrolledBottom}
            >
                <Legend onScroll={handleLegendScroll} ref={legendRef}>
                    <LegendInner>
                        <BaseLegend
                            data={translated}
                            type={type}
                            colors={colors}
                            isFree={isFree}
                            isFullScreen={isFullScreen}
                        />
                    </LegendInner>
                </Legend>
            </LegendWrapper>
        </Wrapper>
    );
});
