import React from 'react';
import { observer } from 'mobx-react-lite';
import { BasePieChart } from './base/pie/base-pie-chart';
import { useRootStore } from '../../../providers/root-store-provider';

type CurrencyChartType = {
    orientation?: 'horizontal' | 'vertical';
    isFullScreen?: boolean;
};

export const CurrencyChart: React.FC<CurrencyChartType> = observer(
    ({ orientation, isFullScreen }: CurrencyChartType) => {
        const { portfolioStore } = useRootStore();
        const { currencyRatio } = useRootStore().statisticStore.ratiosStore;
        if (!currencyRatio) {
            return null;
        }
        return (
            <BasePieChart
                orientation={orientation}
                currency={portfolioStore.selectedPortfolio.portfolioHistoryCurrencyCode}
                data={currencyRatio.data}
                isFullScreen={isFullScreen}
            />
        );
    },
);
