import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconArrowRightWide: React.FC = () => (
    <Icon width="12" height="12" viewBox="0 0 12 12" fill="none">
        <g clipPath="url(#clip0_4097_8850)">
            <path d="M0 6L11.25 6" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
            <path
                d="M6.97481 2.25L11.1748 6L6.97481 9.75"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
        </g>
        <defs>
            <clipPath id="clip0_4097_8850">
                <rect width="12" height="12" fill="currentColor" />
            </clipPath>
        </defs>
    </Icon>
);
