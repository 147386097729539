import React from 'react';
import { observer } from 'mobx-react-lite';
import { BasePieChart } from './base/pie/base-pie-chart';
import { useRootStore } from '../../../providers/root-store-provider';
import { PieChartTypeEnum } from '../../../models/enums/pie-chart-type-enum';

type CountryChartType = {
    isFree?: boolean;
    isFullScreen?: boolean;
};

export const CountryChart: React.FC<CountryChartType> = observer(
    ({ isFree = false, isFullScreen }: CountryChartType) => {
        const { portfolioStore } = useRootStore();
        const { countryRatio } = useRootStore().statisticStore.ratiosStore;

        if (!countryRatio) {
            return null;
        }

        return (
            <BasePieChart
                currency={portfolioStore.selectedPortfolio.portfolioHistoryCurrencyCode}
                type={PieChartTypeEnum.Country}
                data={countryRatio.data}
                isFree={isFree}
                isFullScreen={isFullScreen}
            />
        );
    },
);
