import styled, { css } from 'styled-components';
import { GapSizeType } from '../../../../theme/type/gap-type';
import { TextVariantType } from '../../../../theme/type/text-type';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['padding'].includes(prop),
})<{
    padding?: GapSizeType;
}>`
    ${({ theme, padding }) => css`
        display: flex;
        flex-direction: column;
        padding: ${padding ? theme.gap[padding] : '3.2rem'};
        color: ${theme.palette.color.white.main};
        gap: ${theme.gap.xsmall};
        font-size: ${theme.text.size.vlarge.fontSize};
        line-height: ${theme.text.size.vlarge.fontSize};
        z-index: 99;
    `};
`;

export const Value = styled.div.withConfig({
    shouldForwardProp: (prop) => !['color'].includes(prop),
})<{
    color?: TextVariantType;
}>`
    ${({ theme, color }) => css`
        font-size: ${theme.text.size.xxlarge.fontSize};
        line-height: ${theme.text.size.xxlarge.fontSize};
        color: ${color ? theme.text.variant[color].text : theme.palette.common.textMuted};
        font-weight: 900;
    `};
`;

export const Description = styled.div.withConfig({
    shouldForwardProp: (prop) => !['color'].includes(prop),
})<{
    color?: TextVariantType;
}>`
    ${({ theme, color }) => css`
        font-size: ${theme.text.size.small.fontSize};
        line-height: ${theme.text.size.small.fontSize};
        color: ${color ? theme.text.variant[color].text : theme.palette.common.textMuted};
    `};
`;
