import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../../providers/root-store-provider';
import { CardDashboardPortfolioValue } from '../../../../components/app/card/dashboard/portfolio-value/card-dashboard-portfolio-value';
import { CardDashboardRealizedProfit } from '../../../../components/app/card/dashboard/realized-profit/card-dashboard-realized-profit';
import { CardDashboardUnrealizedProfit } from '../../../../components/app/card/dashboard/unrealized-profit/card-dashboard-unrealized-profit';
import { CardDashboardCurrencyImpact } from '../../../../components/app/card/dashboard/currency-impact/card-dashboard-currency-impact';
import { CardDashboardInvestedValue } from '../../../../components/app/card/dashboard/invested-value/card-dashboard-invested-value';
import { CardDashboardDividends } from '../../../../components/app/card/dashboard/dividends/card-dashboard-dividends';
import { CardDashboardAllTime } from '../../../../components/app/card/dashboard/all-time/card-dashboard-all-time';
import { CardDashboardFees } from '../../../../components/app/card/dashboard/fees/card-dashboard-fees';
import { GridVertical, SlideshowItem } from './dashboard-cards.styled';
import { Slideshow } from '../../../../components/app/swiper/swiper';
import { Responsive } from '../../../../components/app/responsive/responsive';
import { Grid } from '../../../../components/app/grid/grid';
import { SkeletonDashboardCards } from '../../../../components/app/skeleton/dashboard/cards/skeleton-dashboard-cards';

export const DashboardCards: React.FC = observer(() => {
    const { statisticStore, portfolioStore } = useRootStore();
    const { overviewStore } = statisticStore;

    if (overviewStore.loading || portfolioStore.selectedPortfolio.isRecalculating) {
        return <SkeletonDashboardCards />;
    }

    if (!overviewStore.overview) {
        return null;
    }

    return (
        <>
            <Responsive breakpoint={'md'} type={'min'}>
                <Grid gap={1.6} cols={{ xs: 1, vl: 2 }}>
                    <Grid gap={1.6} cols={{ xs: 1 }}>
                        <Grid gap={1.6} cols={{ xs: 1, md: 2 }}>
                            <CardDashboardPortfolioValue />
                            <GridVertical>
                                <CardDashboardRealizedProfit />
                                <CardDashboardUnrealizedProfit />
                            </GridVertical>
                        </Grid>
                        <CardDashboardAllTime />
                    </Grid>
                    <Grid gap={1.6} cols={{ xs: 1 }}>
                        <CardDashboardDividends />
                        <Grid gap={1.6} cols={{ xs: 1, md: 2 }}>
                            <CardDashboardInvestedValue />
                            <GridVertical>
                                <CardDashboardCurrencyImpact />
                                <CardDashboardFees />
                            </GridVertical>
                        </Grid>
                    </Grid>
                </Grid>
            </Responsive>
            <Responsive breakpoint={'md'} type={'max'}>
                <Slideshow>
                    <SlideshowItem>
                        <CardDashboardPortfolioValue />
                    </SlideshowItem>
                    <SlideshowItem>
                        <CardDashboardInvestedValue />
                    </SlideshowItem>
                    <SlideshowItem>
                        <CardDashboardAllTime />
                    </SlideshowItem>
                    <SlideshowItem>
                        <CardDashboardDividends />
                    </SlideshowItem>
                    <SlideshowItem>
                        <CardDashboardRealizedProfit />
                        <CardDashboardUnrealizedProfit />
                    </SlideshowItem>
                    <SlideshowItem>
                        <CardDashboardCurrencyImpact />
                        <CardDashboardFees />
                    </SlideshowItem>
                </Slideshow>
            </Responsive>
        </>
    );
});
