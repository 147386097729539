import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Radio } from '../../../../form/radio/radio';
import { Wrapper, Label } from './allocations-popover-sort.styled';
import { useRootStore } from '../../../../../../providers/root-store-provider';

export const AllocationsPopoverSort: React.FC = observer(() => {
    const { t } = useTranslation();
    const {
        statisticStore: { ratiosStore },
    } = useRootStore();

    return (
        <Wrapper>
            <Label>{t('layout.settings.allocations.sortBy')}</Label>
            <Radio
                checked={ratiosStore.profitOrder === 'profitPercentage'}
                onChange={() => ratiosStore.setProfitPercentageOrder()}
                label={t('layout.settings.allocations.percents')}
            />
            <Radio
                checked={ratiosStore.profitOrder === 'profitValue'}
                onChange={() => ratiosStore.setProfitValueOrder()}
                label={t('layout.settings.allocations.value')}
            />
        </Wrapper>
    );
});
