import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        border-radius: ${theme.radius.large};
        box-shadow: ${theme.shadow.large};
        background-color: ${theme.card.variant.default.background};
        padding: 1.6rem;
        height: 100%;

        @media ${theme.breakpoints.lg.min} {
            padding: 2.2rem;
        }

        @media ${theme.breakpoints.md.max} {
            background-image: url('/images/card-stats/dividends-bg.svg');
            background-position: 100% 100%;
            background-size: auto 10rem;
            background-repeat: no-repeat;
        }
    `};
`;

export const Row = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;

        @media ${theme.breakpoints.md.min} {
            gap: ${theme.gap.small};
            align-items: center;
            flex-direction: row;
        }
    `};
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
    height: 100%;
`;

export const Column = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: ${theme.gap.xsmall};
    `};
`;

export const TitleValue = styled.div`
    ${({ theme }) => css`
        color: ${theme.palette.common.text};
        font-size: ${theme.text.size.large.lineHeight};
        line-height: ${theme.text.size.large.lineHeight};
        font-weight: 900;
    `};
`;

export const ActionsWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        gap: ${theme.gap.xsmall};
        align-items: center;
    `};
`;

export const Label = styled.div.withConfig({
    shouldForwardProp: (prop) => !['bold'].includes(prop),
})<{
    bold?: boolean;
}>`
    ${({ theme, bold }) => css`
        font-size: ${theme.text.size.medium.fontSize};
        line-height: ${theme.text.size.medium.fontSize};
        display: flex;
        gap: ${theme.gap.xsmall};
        gap: 1.4rem;

        ${bold &&
        css`
            font-weight: 900;
        `}
    `};
`;

export const Divider = styled.div`
    ${({ theme }) => css`
        position: relative;

        @media ${theme.breakpoints.md.max} {
            height: 0;
            width: 5rem;
            border-bottom: 0.1rem solid rgba(142, 145, 175, 0.24);
            margin-top: ${theme.gap.vsmall};
            margin-bottom: ${theme.gap.vsmall};
        }

        @media ${theme.breakpoints.md.min} {
            position: relative;
            height: 100%;
            height: 5rem;
            width: 0;
            border-left: 0.1rem solid rgba(142, 145, 175, 0.24);
        }
    `};
`;
