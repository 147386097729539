import * as React from 'react';
import { ActionsWrapper, Content, Title, Wrapper } from './card-title.styled';
import { Badge } from '../../badge/badge';
import { TextVariantType } from '../../../../theme/type/text-type';
import { Tooltip } from '../../tooltip/tooltip';

type CardTitleType = {
    actions?: React.ReactNode;
    title?: string | React.ReactNode;
    tooltip?: string;
    badge?: string | null;
    color?: TextVariantType | 'inherit';
};

export const CardTitle: React.FC<CardTitleType> = ({ actions, title, tooltip, badge, color = 'secondary' }) => {
    return (
        <Wrapper>
            <Content>
                {title && tooltip ? (
                    <Title color={color}>
                        <Tooltip icon content={tooltip} position="bottom-right">
                            {title}
                        </Tooltip>
                    </Title>
                ) : null}
                {title && !tooltip ? <Title color={color}>{title}</Title> : null}
                {badge && (
                    <Badge variant="purple" size="small">
                        {badge}
                    </Badge>
                )}
            </Content>
            {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
        </Wrapper>
    );
};
