import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../../providers/root-store-provider';
import { TabEnumAllocations } from '../../../../models/enums/tab-enum-allocations';
import { TabsMenuWrapper } from '../profit-chart/dashboard-profit-chart.styled';
import { IconArrowDown } from '../../../../components/app/icons/arrow-down';
import { IconArrowUp } from '../../../../components/app/icons/arrow-up';
import { Section } from '../../../../components/app/section/section';
import { Button } from '../../../../components/app/form/button/button';
import { TabsMenu } from '../../../../components/app/tabs/menu/tabs-menu';
import { TabsPanel } from '../../../../components/app/tabs/pane/tabs-panel';
import { AllocationsUnrealizedTable } from '../../../../components/app/tables/allocations/unrealized/allocations-unrealized-table';
import { AllocationsRealizedTable } from '../../../../components/app/tables/allocations/realized/allocations-realized-table';
import { AllocationsRankingsTable } from '../../../../components/app/tables/allocations/rankings/allocations-rankings-table';

export const DashboardAllocations: React.FC = observer(() => {
    const { t } = useTranslation();
    const { authStore, statisticStore, premiumDialogStore } = useRootStore();
    const { ratiosStore } = statisticStore;

    const handleTabChange = useCallback(
        (tab: TabEnumAllocations) => {
            ratiosStore.setTab(tab);
        },
        [ratiosStore],
    );

    const tabsMenu = [
        {
            label: t('pages.index.allocations.tabs.unrealizedTrades'),
            value: TabEnumAllocations.Unrealized,
        },
        {
            label: t('pages.index.allocations.tabs.realizedTrades'),
            value: TabEnumAllocations.Realized,
        },
        {
            label: t('pages.index.allocations.tabs.rankings'),
            value: TabEnumAllocations.Rankings,
        },
    ];

    return (
        <Section
            title={t('pages.index.performanceOfIndividualAssets')}
            footer={
                <>
                    {ratiosStore.canDisplayMore && (
                        <Button
                            variant={'secondary'}
                            size={'small'}
                            icon={ratiosStore.isExpanded ? <IconArrowDown /> : <IconArrowUp />}
                            onClick={() => {
                                if (authStore.userHasFreeTariff) {
                                    premiumDialogStore.open();
                                } else {
                                    ratiosStore.toggleAllocationsExpanded();
                                }
                            }}
                        >
                            {ratiosStore.isExpanded ? t('pages.index.viewMore') : t('pages.index.viewLess')}
                        </Button>
                    )}
                </>
            }
        >
            <TabsMenuWrapper>
                <TabsMenu variant="cards" onChange={handleTabChange} value={ratiosStore.currentTab} items={tabsMenu} />
            </TabsMenuWrapper>
            <TabsPanel activeTab={ratiosStore.currentTab} value={TabEnumAllocations.Unrealized}>
                <AllocationsUnrealizedTable />
            </TabsPanel>
            <TabsPanel activeTab={ratiosStore.currentTab} value={TabEnumAllocations.Realized}>
                <AllocationsRealizedTable />
            </TabsPanel>
            <TabsPanel activeTab={ratiosStore.currentTab} value={TabEnumAllocations.Rankings}>
                <AllocationsRankingsTable />
            </TabsPanel>
        </Section>
    );
});
