import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useRootStore } from '../../../../providers/root-store-provider';
import { NoData } from '../../../../components/app/no-data/no-data';
import { routes } from '../../../../config/routes';
import { Button } from '../../../../components/app/form/button/button';
import { IconPlus } from '../../../../components/app/icons/plus';
import { IconDownload } from '../../../../components/app/icons/download';

export const DashboardNoData: React.FC = observer(() => {
    const { t } = useTranslation();
    const { tradeStore, currencyStore } = useRootStore();
    const { tradeDialogStore, tradeImportDialogStore } = tradeStore;
    const router = useRouter();

    useEffect(() => {
        currencyStore.fetchCurrencyList();
    });

    return (
        <NoData
            action={
                <>
                    <Button
                        variant="positive"
                        icon={<IconDownload />}
                        size={{ xs: 'medium', md: 'vlarge' }}
                        onClick={() => {
                            router.push(routes.app.trades);
                            tradeImportDialogStore.openImportDialog();
                            tradeDialogStore.fetchSymbols();
                        }}
                    >
                        {t('pages.trades.import')}
                    </Button>
                    <Button
                        variant="primary"
                        icon={<IconPlus />}
                        size={{ xs: 'medium', md: 'vlarge' }}
                        onClick={() => {
                            router.push(routes.app.trades);
                            tradeDialogStore.openCreateDialog();
                            tradeDialogStore.fetchSymbols();
                        }}
                    >
                        {t('pages.trades.addTrade')}
                    </Button>
                </>
            }
        >
            {t('layout.tables.noData')}
        </NoData>
    );
});
