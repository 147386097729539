import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        border-radius: ${theme.radius.large};
        box-shadow: ${theme.shadow.large};
        overflow: hidden;
        background-color: ${theme.card.variant.default.background};
        padding: 1.6rem;
        height: 100%;

        @media ${theme.breakpoints.lg.min} {
            padding: 2.2rem;
        }
    `};
`;
