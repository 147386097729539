import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Wrapper } from './allocations-rankings-table.styled';
import { Grid } from '../../../grid/grid';
import { Card } from '../../../card/card';
import { AllocationsRankingsGainersTable } from './gainers/allocations-rankings-gainers-table';
import { AllocationsRankingsLosersTable } from './losers/allocations-rankings-losers-table';

export const AllocationsRankingsTable: React.FC = observer(() => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Grid cols={{ xs: 1, vl: 2 }}>
                <Card title={t('layout.tables.allocations.rankings.gainers.title')} shadow="large">
                    <AllocationsRankingsGainersTable />
                </Card>

                <Card title={t('layout.tables.allocations.rankings.losers.title')} shadow="large">
                    <AllocationsRankingsLosersTable />
                </Card>
            </Grid>
        </Wrapper>
    );
});
