import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { ActionsWrapper, Divider, Label, TitleValue, Column, Wrapper, Row } from './card-dashboard-dividends.styled';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { routes } from '../../../../../config/routes';
import { Tooltip } from '../../../tooltip/tooltip';
import { CardUpdated } from '../card-loading/card-updated';
import { IconArrowRightWide } from '../../../icons/arrow-right-wide';
import { IconButton } from '../../../icon/button/icon-button';
import { ValuePercents } from '../../../value/percents/value-precents';
import { ValueAmount } from '../../../value/amount/value-amount';
import { CardTitle } from '../../title/card-title';

export const CardDashboardDividends: React.FC = observer(() => {
    const { t } = useTranslation();
    const { authStore, statisticStore, portfolioStore } = useRootStore();
    const { overview } = statisticStore.overviewStore;
    const router = useRouter();

    const portfolioCurrency = portfolioStore.selectedPortfolio.portfolioHistoryCurrencyCode;

    if (!overview) {
        return null;
    }

    const getActions = useMemo(() => {
        return (
            <ActionsWrapper>
                <IconButton
                    icon={<IconArrowRightWide />}
                    variation={'outlined'}
                    size={'small'}
                    onClick={async () => {
                        await router.push(`${routes.app.dividends}`);
                    }}
                />
            </ActionsWrapper>
        );
    }, []);

    return (
        <CardUpdated fields={['dividends', 'dividendsYield', 'dividendsYieldOnCost', 'dividendsNextYearPrediction']}>
            <Wrapper>
                <CardTitle
                    title={t('layout.statisticsCard.dividends.title')}
                    badge={authStore.userHasFreeTariff ? 'Premium' : null}
                    actions={getActions}
                />
                <Row>
                    <Column>
                        <Label>
                            <Label bold>
                                <ValuePercents
                                    amount={overview.dividendsYield}
                                    locked={authStore.userHasFreeTariff}
                                    mode="pure"
                                />
                            </Label>
                            {t('layout.statisticsCard.dividends.yield')}
                        </Label>
                        <Label>
                            <Label bold>
                                <ValuePercents
                                    amount={overview.dividendsYieldOnCost}
                                    locked={authStore.userHasFreeTariff}
                                    mode="pure"
                                />
                            </Label>
                            {t('layout.statisticsCard.dividends.yieldOnCost')}
                        </Label>
                    </Column>
                    <Divider />
                    <Column>
                        <Label>
                            <Tooltip
                                position={'bottom-right'}
                                icon
                                content={t('layout.statisticsCard.dividends.last12Months')}
                            >
                                {t('layout.statisticsCard.dividends.prediction')}
                            </Tooltip>
                        </Label>
                        <Label bold>
                            <ValueAmount
                                amount={overview.dividendsNextYearPrediction}
                                currency={portfolioCurrency}
                                locked={authStore.userHasFreeTariff}
                                mode="pure"
                                hidable
                            />
                        </Label>
                    </Column>
                    <Divider />
                    <Column>
                        <Label>{t('layout.statisticsCard.dividends.payout')}</Label>
                        <TitleValue>
                            <ValueAmount
                                amount={overview.dividends}
                                currency={portfolioCurrency}
                                locked={authStore.userHasFreeTariff}
                                mode="pure"
                                hidable
                            />
                        </TitleValue>
                    </Column>
                </Row>
            </Wrapper>
        </CardUpdated>
    );
});
