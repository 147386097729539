import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Title, Wrapper } from './card-dashboard-fees.styled';
import { ValueDescription } from '../../value-description/value-description';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { CardUpdated } from '../card-loading/card-updated';
import { ValueAmount } from '../../../value/amount/value-amount';
import { ValuePercents } from '../../../value/percents/value-precents';
import { CardTitle } from '../../title/card-title';
import { Switch } from '../../../form/switch/switch';
import { IconCheck } from '../../../icons/check';
import { Tooltip } from '../../../tooltip/tooltip';

export const CardDashboardFees: React.FC = observer(() => {
    const { t } = useTranslation();
    const { overview } = useRootStore().statisticStore.overviewStore;
    const {
        portfolioStore,
        statisticStore: { overviewStore },
    } = useRootStore();

    return (
        <CardUpdated fields={['fees', 'feesPercentage']}>
            <Wrapper>
                <CardTitle
                    title={<Title>{t('layout.statisticsCard.fees.title')}</Title>}
                    actions={
                        <Tooltip content={t('layout.statisticsCard.fees.subtractFees')} position="top-right">
                            <Switch
                                variant="negative"
                                checked={overviewStore.calculateFees}
                                name="switchThemeState"
                                iconOff={' '}
                                iconOn={<IconCheck />}
                                onChange={() => overviewStore.setCalculateFees(!overviewStore.calculateFees)}
                            />
                        </Tooltip>
                    }
                />

                {overview && (
                    <ValueDescription
                        value={
                            <ValueAmount
                                amount={overview?.fees}
                                currency={portfolioStore.selectedPortfolio.currency?.code || ''}
                                nullValue={0}
                                mode="pure"
                                hidable
                            />
                        }
                        description={
                            <Trans
                                i18nKey={`layout.statisticsCard.fees.ofInvestedAmount`}
                                components={{
                                    strong: <strong />,
                                    value: (
                                        <ValuePercents amount={overview?.feesPercentage} nullValue={0} mode="pure" />
                                    ),
                                }}
                            />
                        }
                        padding={'none'}
                        valueColor={'white'}
                        descriptionColor={'white'}
                    />
                )}
            </Wrapper>
        </CardUpdated>
    );
});
