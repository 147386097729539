import React, { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Content, ContentInner, Wrapper, Trigger, TriggerChildren } from './popover.styled';

type PopoverType = {
    children: React.ReactNode;
    content: React.ReactNode | string;
    position?: 'bottom' | 'top' | 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left';
    arrowPosition?: number;
    block?: boolean;
};

export const Popover: React.FC<PopoverType> = ({
    children,
    content,
    position = 'top-left',
    arrowPosition,
    block = false,
}) => {
    const [hoveredOnTrigger, setHoveredOnTrigger] = useState(false);
    const [hoveredOnContent, setHoveredOnContent] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number } | null>(null);
    const [tooltipContent, setTooltipContent] = useState(content);

    const triggerRef = useRef<HTMLDivElement>(null);
    const iconRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (hoveredOnTrigger || hoveredOnContent) {
            setTooltipVisible(true);
        } else {
            setTooltipVisible(false);
        }
    }, [hoveredOnTrigger, hoveredOnContent]);

    const calculateTooltipPosition = () => {
        if (tooltipRef.current && (triggerRef.current || iconRef.current)) {
            if (triggerRef.current) {
                const triggerRect = triggerRef.current.getBoundingClientRect();
                const tooltipRect = tooltipRef.current.getBoundingClientRect();

                let top = 0;
                let left = 0;

                switch (position) {
                    case 'bottom':
                        top = triggerRect.bottom + window.scrollY;
                        left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2 + window.scrollX;
                        break;
                    case 'top':
                        top = triggerRect.top - tooltipRect.height + window.scrollY;
                        left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2 + window.scrollX;
                        break;
                    case 'top-left':
                        top = triggerRect.top - tooltipRect.height + window.scrollY;
                        left = triggerRect.left + window.scrollX;
                        break;
                    case 'top-right':
                        top = triggerRect.top - tooltipRect.height + window.scrollY;
                        left = triggerRect.right - tooltipRect.width + window.scrollX;
                        break;
                    case 'bottom-left':
                        top = triggerRect.bottom + window.scrollY;
                        left = triggerRect.left + window.scrollX;
                        break;
                    case 'bottom-right':
                        top = triggerRect.bottom + window.scrollY;
                        left = triggerRect.right - tooltipRect.width + window.scrollX;
                        break;
                    default:
                        top = triggerRect.bottom + window.scrollY;
                        left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2 + window.scrollX;
                        break;
                }

                setTooltipPosition({ top, left });
            }
        }
    };

    useEffect(() => {
        if (tooltipVisible) {
            calculateTooltipPosition();
        }
    }, [tooltipVisible, tooltipContent]);

    useEffect(() => {
        setTooltipContent(content);
    }, [content]);

    const handleTriggerMouseEnter = () => {
        setHoveredOnTrigger(true);
    };

    const handleTriggerMouseLeave = () => {
        setHoveredOnTrigger(false);
    };

    const handleContentMouseEnter = () => {
        setHoveredOnContent(true);
    };

    const handleContentMouseLeave = () => {
        setHoveredOnContent(false);
    };

    return (
        <Wrapper block={block}>
            <Trigger
                ref={triggerRef}
                block={block}
                onMouseEnter={handleTriggerMouseEnter}
                onMouseLeave={handleTriggerMouseLeave}
            >
                <TriggerChildren block={block}>{children}</TriggerChildren>
            </Trigger>
            {tooltipVisible &&
                createPortal(
                    <Content
                        ref={tooltipRef}
                        position={position}
                        style={{ top: tooltipPosition?.top, left: tooltipPosition?.left }}
                        onMouseEnter={handleContentMouseEnter}
                        onMouseLeave={handleContentMouseLeave}
                    >
                        <ContentInner position={position} arrowPosition={arrowPosition}>
                            {content}
                        </ContentInner>
                    </Content>,
                    document.body,
                )}
        </Wrapper>
    );
};

export default Popover;
