import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${() => css`
        display: flex;
        flex-direction: column;
    `}
`;

export const Label = styled.div`
    ${({ theme }) => css`
        margin-bottom: ${theme.gap.xsmall};
    `}
`;
