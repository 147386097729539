import styled, { css } from 'styled-components';
import { getOpacity } from '../../../../../theme/utils/get-rgb';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['orientation', 'isFullScreen'].includes(prop),
})<{
    orientation?: 'vertical' | 'horizontal';
    isFullScreen?: boolean;
}>(
    ({ theme, orientation, isFullScreen }) => css`
        gap: ${theme.gap.vsmall};
        height: 100%;

        @media ${theme.breakpoints.xxl.min} {
            gap: ${theme.gap.medium};
        }

        ${!isFullScreen &&
        css`
            ${orientation === 'horizontal' &&
            css`
                display: grid;
                grid-template-columns: 1fr 1.5fr;
            `}

            ${orientation === 'vertical' &&
            css`
                display: flex;
                flex-direction: column;
            `}
        `}

        ${isFullScreen &&
        css`
            height: calc(100vh - 10.4rem);
            display: grid;
            grid-template-columns: 1fr 1.5fr;

            @media ${theme.breakpoints.xxl.min} {
                gap: 10rem;
                padding: 5rem;
                height: calc(100% - 10rem);
            }

            @media ${theme.breakpoints.lg.max} {
                ${orientation === 'horizontal' &&
                css`
                    display: flex;
                    flex-direction: column;
                    gap: 4rem;
                `}
            }
        `}
    `,
);

export const Chart = styled.div.withConfig({
    shouldForwardProp: (prop) => !['orientation', 'isFree', 'isFullScreen'].includes(prop),
})<{
    orientation?: 'vertical' | 'horizontal';
    isFree?: boolean;
    isFullScreen?: boolean;
}>(
    ({ orientation, isFree, isFullScreen }) => css`
        position: relative;
        display: inline-flex;
        width: 100%;
        padding-bottom: 100%;

        ${isFullScreen &&
        css`
            padding-bottom: 50%;
        `}

        ${isFree &&
        css`
            filter: blur(0.3rem);
        `}

        ${orientation === 'vertical' &&
        css`
            padding-bottom: 60%;
            width: 60%;
            margin: 0 auto;
        `}

        svg {
            overflow: visible;
        }
    `,
);

export const ChartInner = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isFullScreen'].includes(prop),
})<{
    isFullScreen?: boolean;
}>`
    ${({ theme, isFullScreen }) => css`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        &:before {
            content: '';
            //display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 30%;
            padding-bottom: 30%;
            border-radius: 100%;
            transform: translate(-50%, -50%);
            border: 0.1rem solid ${theme.palette.common.border};

            ${isFullScreen &&
            css`
                @media ${theme.breakpoints.lg.max} {
                    width: 15%;
                    padding-bottom: 15%;
                }
            `}
        }
    `}
`;

export const LegendWrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isLegendScrolledTop', 'isLegendScrolledBottom', 'isFullScreen'].includes(prop),
})<{
    isLegendScrolledTop: boolean;
    isLegendScrolledBottom: boolean;
    isFullScreen?: boolean;
}>`
    ${({ theme, isLegendScrolledTop, isLegendScrolledBottom, isFullScreen }) => css`
        position: relative;
        flex: 1;
        margin: ${isFullScreen ? 0 : '-2rem -2rem -2rem 0'};

        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 5rem;
            z-index: 10;
        }

        &:before {
            top: 0;
            transform: translateY(0);
            opacity: 1;
            transition: all ${theme.base.transition};
            background: linear-gradient(
                0deg,
                ${getOpacity(theme.palette.background.body, 0)} 0%,
                ${getOpacity(theme.palette.background.body, 1)} 100%
            );

            ${isLegendScrolledTop &&
            css`
                transform: translateY(-5rem);
                opacity: 0;
            `}
        }

        &:after {
            bottom: 0;
            transform: translateY(0);
            opacity: 1;
            transition: all ${theme.base.transition};
            background: linear-gradient(
                180deg,
                ${getOpacity(theme.palette.background.body, 0)} 0%,
                ${getOpacity(theme.palette.background.body, 1)} 100%
            );

            ${isLegendScrolledBottom &&
            css`
                transform: translateY(5rem);
                opacity: 0;
            `}
        }
    `}
`;

export const Legend = styled.div`
    overflow: auto;
    position: relative;
    height: 100%;
`;

export const LegendInner = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
