import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { LoadingAnimation, Wrapper } from './card-loading.styled';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { OverviewMobxDto } from '../../../../../models/mobx/dtos/statistic/overview-mobx-dto';

interface CardUpdatedInterface {
    fields?: (keyof OverviewMobxDto)[];
    children: React.ReactNode;
}

export const CardUpdated: React.FC<CardUpdatedInterface> = observer(({ children, fields }) => {
    const { statisticStore } = useRootStore();
    const { overviewStore } = useRootStore().statisticStore;

    const animationComponent: React.ReactNode = useMemo(() => {
        if (!statisticStore.loading && fields && fields.length > 0) {
            let shouldAnimate = false;
            fields.map((field) => {
                if (overviewStore.getIsFieldDirty(field)) {
                    shouldAnimate = true;
                }
                return null;
            });
            if (shouldAnimate) {
                overviewStore.removeDirtyFieldsWithDelay();
                return <LoadingAnimation />;
            }
            return null;
        }
        return null;
    }, [statisticStore.loading, overviewStore.overview, fields, overviewStore.overviewDirtyFields]);

    return (
        <Wrapper>
            {animationComponent}
            {children}
        </Wrapper>
    );
});
