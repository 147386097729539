import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { Content, Label, Value, Wrapper } from './card-dashboard-invested-value.styled';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { routes } from '../../../../../config/routes';
import { CardUpdated } from '../card-loading/card-updated';
import { IconArrowRightWide } from '../../../icons/arrow-right-wide';
import { IconButton } from '../../../icon/button/icon-button';
import { ValueAmount } from '../../../value/amount/value-amount';
import { CardTitle } from '../../title/card-title';
import { ValueDescription } from '../../value-description/value-description';

export const CardDashboardInvestedValue: React.FC = observer(() => {
    const { t } = useTranslation();
    const router = useRouter();

    const rootStore = useRootStore();
    const { portfolioStore, statisticStore } = rootStore;
    const { overviewStore } = statisticStore;
    const { overview } = overviewStore;

    const portfolioCurrency = portfolioStore.selectedPortfolio.portfolioHistoryCurrencyCode;

    return (
        <CardUpdated fields={['invested', 'investmentPeriod', 'averageInvestment']}>
            <Wrapper>
                <CardTitle
                    title={t('layout.statisticsCard.investedValue.title')}
                    tooltip={t('layout.statisticsCard.investedValue.tooltip')}
                    actions={
                        <IconButton
                            icon={<IconArrowRightWide />}
                            variation={'outlined'}
                            size={'small'}
                            onClick={async () => {
                                await router.push(`${routes.app.invested}`);
                            }}
                        />
                    }
                />
                <Content>
                    {overview && (
                        <>
                            <ValueDescription
                                padding={'none'}
                                value={
                                    <ValueAmount
                                        amount={overview.invested}
                                        currency={portfolioCurrency}
                                        mode="pure"
                                        hidable
                                    />
                                }
                                description={
                                    <Trans
                                        i18nKey={`layout.statisticsCard.investedValue.assets`}
                                        values={{
                                            numberOfAssets: portfolioStore.selectedPortfolio.numberOfAssets,
                                            numberOfTrades: portfolioStore.selectedPortfolio.numberOfTrades,
                                        }}
                                        components={{ strong: <strong /> }}
                                    />
                                }
                                valueColor={'secondary'}
                            />

                            <br />

                            <Label>{t('layout.statisticsCard.investedValue.investmentPeriod')}</Label>
                            <Value>
                                {t('layout.statisticsCard.investedValue.months', { value: overview.investmentPeriod })}
                            </Value>

                            <br />

                            <Label>{t('layout.statisticsCard.investedValue.monthlyAverage')}</Label>
                            <Value>
                                <ValueAmount
                                    amount={overview.averageInvestment}
                                    currency={portfolioCurrency}
                                    mode="pure"
                                    hidable
                                />
                            </Value>
                        </>
                    )}
                </Content>
            </Wrapper>
        </CardUpdated>
    );
});
