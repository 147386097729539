import React from 'react';
import { observer } from 'mobx-react-lite';
import { STATISTIC_INTERVALS } from '../../../models/stores/statistic/statistic-store';
import { useRootStore } from '../../../providers/root-store-provider';
import { ButtonFilter } from '../form/button-filter/button-filter';
import { Button } from '../form/button/button';
import { PeriodSwitcherSkeleton } from './skeleton/period-switcher-skeleton';

type PeriodSwitcherType = {
    loading?: boolean;
};
export const PeriodSwitcher: React.FC<PeriodSwitcherType> = observer(({ loading }: PeriodSwitcherType) => {
    const { statisticStore } = useRootStore();

    if (loading) {
        return <PeriodSwitcherSkeleton />;
    }

    return (
        <ButtonFilter>
            {STATISTIC_INTERVALS.map((period) => {
                const isSelected = period.type === statisticStore.type;

                return (
                    <Button
                        key={period.title}
                        size={{ xs: 'vsmall', md: 'small' }}
                        onClick={async () => {
                            await statisticStore.setType(period.type);
                        }}
                        variant={isSelected ? 'secondary' : 'default'}
                        variation={isSelected ? 'filled' : 'outlined'}
                    >
                        {period.title}
                    </Button>
                );
            })}
        </ButtonFilter>
    );
});
