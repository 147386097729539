import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        border-radius: ${theme.radius.large};
        box-shadow: ${theme.shadow.large};
        background-color: ${theme.card.variant.default.background};
        overflow: hidden;
        padding: 1.6rem;
        height: 100%;
        height: 37.6rem;

        @media ${theme.breakpoints.lg.min} {
            padding: 2.2rem;
        }
    `};
`;

export const Content = styled.div`
    ${({ theme }) => css`
        gap: ${theme.gap.xsmall};
    `};
`;

export const Label = styled.div`
    ${({ theme }) => css`
        font-size: ${theme.text.size.medium.fontSize};
        line-height: ${theme.text.size.vlarge.lineHeight};
    `};
`;

export const Value = styled.div<{ positive?: boolean }>`
    ${({ theme, positive }) => css`
        font-size: ${theme.text.size.medium.fontSize};
        line-height: ${theme.text.size.vlarge.lineHeight};
        font-weight: 700;

        ${positive &&
        css`
            color: ${theme.palette.color.positive.main};
        `}
    `};
`;
