import React from 'react';
import { observer } from 'mobx-react-lite';
import { STATISTIC_INTERVALS } from '../../../../models/stores/statistic/statistic-store';
import { ButtonFilter } from '../../form/button-filter/button-filter';
import SkeletonButton from '../../skeleton/button/skeleton-button';

export const PeriodSwitcherSkeleton: React.FC = observer(() => {
    return (
        <ButtonFilter>
            {STATISTIC_INTERVALS.map((period) => {
                return (
                    <SkeletonButton key={period.title} size={{ xs: 'vsmall', md: 'small' }}>
                        {period.title}
                    </SkeletonButton>
                );
            })}
        </ButtonFilter>
    );
});
