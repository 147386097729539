import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconDownload: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <g>
            <path
                d="M23.88,15.28l-3-5.51a1.91,1.91,0,0,0-1.68-1H17a1,1,0,0,0,0,2h2a.26.26,0,0,1,.22.13l2.17,4a.25.25,0,0,1,0,.25.24.24,0,0,1-.21.12H17.5a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,1-1.5,1.5h-5A1.5,1.5,0,0,1,8,16.75a1.5,1.5,0,0,0-1.5-1.5H2.8a.26.26,0,0,1-.21-.12.25.25,0,0,1,0-.25l2.09-4a.24.24,0,0,1,.22-.13H6.5a1,1,0,0,0,0-2H4.74a1.91,1.91,0,0,0-1.68,1L.12,15.28a1.42,1.42,0,0,0-.12.47v6a1.5,1.5,0,0,0,1.5,1.5h21a1.5,1.5,0,0,0,1.5-1.5v-6A1.19,1.19,0,0,0,23.88,15.28Z"
                fill="currentColor"
            />
            <path
                d="M8.65,7.45l2.75,3.67a.78.78,0,0,0,.6.3.77.77,0,0,0,.6-.3l2.75-3.67a.75.75,0,0,0,.07-.79.74.74,0,0,0-.67-.41h-1A.25.25,0,0,1,13.5,6V2.25a1.5,1.5,0,0,0-3,0V6a.25.25,0,0,1-.25.25h-1a.76.76,0,0,0-.67.41.77.77,0,0,0,.07.79Z"
                fill="currentColor"
            />
        </g>
    </Icon>
);
