import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Name, Symbol, Value, Wrapper } from './base-legend-item.styled';
import { PieChartTypeEnum } from '../../../../../../models/enums/pie-chart-type-enum';
import { ValuePercents } from '../../../../value/percents/value-precents';
import { ValueString } from '../../../../value/string/value-string';
import { PieChartDataMobxDto } from '../../../../../../models/mobx/dtos/statistic/pie-chart-data-mobx-dto';

export interface BaseLegendItemProps {
    data: PieChartDataMobxDto;
    type?: PieChartTypeEnum;
    color: string;
    isFree: boolean;
    isFullScreen?: boolean;
}

export const BaseLegendItem: React.FC<BaseLegendItemProps> = observer(
    ({ data, type, color, isFree, isFullScreen }: BaseLegendItemProps) => {
        const { t, i18n } = useTranslation();

        return (
            <Wrapper isFullScreen={isFullScreen}>
                <Name isFullScreen={isFullScreen}>
                    <Symbol color={color} isFree={isFree} isFullScreen={isFullScreen} />
                    <ValueString
                        text={
                            i18n.exists(`pages.index.${type}.${data.id}`)
                                ? t(`pages.index.${type}.${data.id}`)
                                : data.id
                        }
                        locked={isFree}
                        mode="pure"
                    />
                </Name>
                <Value isFullScreen={isFullScreen}>
                    <ValuePercents amount={data.value} fractionDigits={1} locked={isFree} mode="pure" />
                </Value>
            </Wrapper>
        );
    },
);
