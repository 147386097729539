import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ disableBorderTop?: boolean }>`
    ${({ theme, disableBorderTop = false }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-height: 100%;
        border: 0.1rem solid ${theme.card.variant.default.border};
        gap: ${theme.gap.small};
        padding: 5rem ${theme.gap.medium} 8rem;
        border-radius: ${theme.radius.medium};
        background: ${theme.card.variant.default.background};
        border-top-left-radius: ${disableBorderTop ? 'unset' : theme.radius.medium};
        margin-bottom: -${theme.gap.small};
        overflow: hidden;

        @media ${theme.breakpoints.lg.max} {
            background: none;
            border: none;
            padding: 0;
            gap: ${theme.gap.vsmall};

            td,
            th {
                padding: 1.6rem 0;
                font-size: ${theme.text.size.vsmall.fontSize};
                line-height: ${theme.text.size.vsmall.fontSize};
            }
        }
    `}
`;
