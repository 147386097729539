import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { SubscriptionEnum, FontWeightEnum, TextVariantColorEnum } from '@app/common';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { Table } from '../../../table/table';
import { TableCell } from '../../../table/cell/table-cell';
import { TableRow } from '../../../table/row/table-row';
import { TableBody } from '../../../table/body/table-body';
import { Text } from '../../../text/text';
import { NoData } from '../../../no-data/no-data';
import { TableHead } from '../../../table/head/table-head';
import { Symbol } from '../../../symbol/symbol';
import { routes } from '../../../../../config/routes';
import { Wrapper } from './allocations-unrealized-table.styled';
import { SortButton } from '../../../sorting/sort-button';
import { Stack } from '../../../stack/stack';
import { ValuePercents } from '../../../value/percents/value-precents';
import { ValueQuantity } from '../../../value/quantity/value-quantity';
import { ValueAmount } from '../../../value/amount/value-amount';
import { AllocationsProgressBar } from '../../../allocations-progress-bar/allocations-progress-bar';
import { Tooltip } from '../../../tooltip/tooltip';
import { AllocationsUnrealizedOverview } from './allocations-unrealized-overview';
import { SkeletonUnrealizedAllocationsTable } from '../../../skeleton/dashboard/allocations/unrealized-allocations/skeleton-unrealized-allocations-table';
import { SymbolTypeEnum } from '../../../../../defs/api';
import { getColor } from '../../utils/get-color';
import { Popover } from '../../../popover/popover';
import { AllocationsPopoverSort } from '../popover/sort/allocations-popover-sort';

export const AllocationsUnrealizedTable: React.FC = observer(() => {
    const { t } = useTranslation();
    const {
        portfolioStore,
        statisticStore: {
            ratiosStore,
            ratiosStore: { unrealizedAllocations, unrealizedAllocationsOverview },
        },
        authStore,
    } = useRootStore();

    const user = authStore.userProfile;

    const isFree = user?.subscription === SubscriptionEnum.Free;

    if (ratiosStore.loading || portfolioStore.selectedPortfolio.isRecalculating) {
        return <SkeletonUnrealizedAllocationsTable />;
    }

    if (unrealizedAllocations.length === 0) {
        return (
            <Wrapper>
                <NoData>{t('layout.tables.noData')}</NoData>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Table mobileBreakUnder="lg">
                <TableHead>
                    <TableRow>
                        <TableCell width={'50%'}>
                            <Stack direction={'horizontal'} gap={'xsmall'} alignItems={'center'}>
                                {t('layout.tables.performanceOfIndividualAssets.asset')}
                                <SortButton
                                    order={ratiosStore.getFieldSortMethod('weight')}
                                    onSortChange={(newOrder) => {
                                        ratiosStore.getFieldSortMethod('weight');
                                        ratiosStore.setSortModel({
                                            field: 'weight',
                                            method: newOrder,
                                        });
                                    }}
                                />
                            </Stack>
                        </TableCell>
                        <TableCell width={'10%'}>
                            <Stack direction={'horizontal'} gap={'xsmall'} alignItems={'center'}>
                                {t('layout.tables.performanceOfIndividualAssets.qty')}
                                <SortButton
                                    order={ratiosStore.getFieldSortMethod('invested')}
                                    onSortChange={(newOrder) => {
                                        ratiosStore.getFieldSortMethod('invested');
                                        ratiosStore.setSortModel({
                                            field: 'invested',
                                            method: newOrder,
                                        });
                                    }}
                                />
                            </Stack>
                        </TableCell>
                        <TableCell width={'10%'}>
                            <Stack direction={'horizontal'} gap={'xsmall'} alignItems={'center'}>
                                {t('layout.tables.performanceOfIndividualAssets.price')}
                                <SortButton
                                    order={ratiosStore.getFieldSortMethod('value')}
                                    onSortChange={(newOrder) => {
                                        ratiosStore.getFieldSortMethod('value');
                                        ratiosStore.setSortModel({
                                            field: 'value',
                                            method: newOrder,
                                        });
                                    }}
                                />
                            </Stack>
                        </TableCell>
                        <TableCell width={'10%'}>
                            <Stack direction={'horizontal'} gap={'xsmall'} alignItems={'center'}>
                                {t('layout.tables.performanceOfIndividualAssets.taxFree')}
                                <SortButton
                                    order={ratiosStore.getFieldSortMethod('taxFreeValue')}
                                    onSortChange={(newOrder) => {
                                        ratiosStore.getFieldSortMethod('taxFreeValue');
                                        ratiosStore.setSortModel({
                                            field: 'taxFreeValue',
                                            method: newOrder,
                                        });
                                    }}
                                />
                            </Stack>
                        </TableCell>
                        <TableCell align="right" width={'10%'}>
                            <Stack direction={'horizontal'} gap={'xsmall'} alignItems={'center'}>
                                {t('layout.tables.performanceOfIndividualAssets.currencyImpact')}
                                <SortButton
                                    order={ratiosStore.getFieldSortMethod('currencyImpactValue')}
                                    onSortChange={(newOrder) => {
                                        ratiosStore.getFieldSortMethod('currencyImpactValue');
                                        ratiosStore.setSortModel({
                                            field: 'currencyImpactValue',
                                            method: newOrder,
                                        });
                                    }}
                                />
                            </Stack>
                        </TableCell>
                        <TableCell align="right" width={'10%'}>
                            <Stack direction={'horizontal'} gap={'xsmall'} alignItems={'center'}>
                                {t('layout.tables.performanceOfIndividualAssets.dividendsValue')}
                                <SortButton
                                    order={ratiosStore.getFieldSortMethod('dividendsValue')}
                                    onSortChange={(newOrder) => {
                                        ratiosStore.getFieldSortMethod('dividendsValue');
                                        ratiosStore.setSortModel({
                                            field: 'dividendsValue',
                                            method: newOrder,
                                        });
                                    }}
                                />
                            </Stack>
                        </TableCell>
                        <TableCell align="right" width={'10%'}>
                            <Stack direction={'horizontal'} gap={'xsmall'} alignItems={'center'}>
                                <Tooltip
                                    icon
                                    content={t('layout.tables.performanceOfIndividualAssets.profitTooltip')}
                                    position="bottom-right"
                                >
                                    {t('layout.tables.performanceOfIndividualAssets.profit')}
                                </Tooltip>
                                <Popover position="top-right" content={<AllocationsPopoverSort />}>
                                    <SortButton
                                        order={ratiosStore.getFieldSortMethod(ratiosStore.profitOrder)}
                                        onSortChange={(newOrder) => {
                                            ratiosStore.getFieldSortMethod(ratiosStore.profitOrder);
                                            ratiosStore.setSortModel({
                                                field: ratiosStore.profitOrder,
                                                method: newOrder,
                                            });
                                        }}
                                    />
                                </Popover>
                            </Stack>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {unrealizedAllocations.map((row) => {
                        const singleAmountUnit = row.type === SymbolTypeEnum.Ct ? 'kus' : 'akcie';
                        const multipleAmountUnit = row.type === SymbolTypeEnum.Ct ? 'kusů' : 'akcií';
                        return (
                            <TableRow key={row.symbol}>
                                <TableCell>
                                    <AllocationsProgressBar value={row.weight}>
                                        <Symbol
                                            symbol={row.symbol}
                                            type={row.type}
                                            url={`${routes.app.symbols}/${row.exchangeId}/${row.symbol}`}
                                            name={
                                                row.type === SymbolTypeEnum.Ct
                                                    ? `${row.symbolName} (${row.symbolCurrency})`
                                                    : `${row.symbolName}`
                                            }
                                            description={
                                                <>
                                                    <ValuePercents amount={row.weight} mode="pure" />{' '}
                                                    <ValueQuantity
                                                        amount={row.amount}
                                                        mode="pure"
                                                        suffix={multipleAmountUnit}
                                                        inParentheses
                                                    />
                                                </>
                                            }
                                            ticker={row.symbol}
                                        />
                                    </AllocationsProgressBar>
                                </TableCell>
                                <TableCell label={t('layout.tables.performanceOfIndividualAssets.qty')}>
                                    <ValueAmount
                                        amount={row.invested}
                                        currency={row.currency}
                                        color={TextVariantColorEnum.Secondary}
                                        fontWeight={FontWeightEnum.SemiBold}
                                        nullValue={0}
                                        hidable
                                    />
                                    <Text size="small">
                                        <ValueAmount
                                            amount={row.averageInvested}
                                            currency={`${row.currency} / ${singleAmountUnit}`}
                                            color={TextVariantColorEnum.Secondary}
                                            nullValue={0}
                                            inParentheses
                                            hidable
                                        />
                                    </Text>
                                </TableCell>
                                <TableCell label={t('layout.tables.performanceOfIndividualAssets.price')}>
                                    <ValueAmount
                                        amount={row.value}
                                        currency={row.currency}
                                        color={TextVariantColorEnum.Secondary}
                                        fontWeight={FontWeightEnum.SemiBold}
                                        nullValue={0}
                                        hidable
                                    />
                                    <Text size="small">
                                        <ValueAmount
                                            amount={row.averageValue}
                                            currency={`${row.currency} / ${singleAmountUnit}`}
                                            color={TextVariantColorEnum.Secondary}
                                            nullValue={0}
                                            inParentheses
                                            hidable
                                        />
                                    </Text>
                                </TableCell>
                                <TableCell label={t('layout.tables.performanceOfIndividualAssets.taxFree')}>
                                    <ValueAmount
                                        amount={row.taxFreeValue}
                                        currency={row.currency}
                                        fontWeight={FontWeightEnum.SemiBold}
                                        nullValue={0}
                                        hidable
                                    />
                                    <Text size="small">
                                        <ValueQuantity
                                            color={getColor(row.taxFreeAmount)}
                                            amount={row.taxFreeAmount}
                                            suffix={multipleAmountUnit}
                                            inParentheses
                                        />
                                    </Text>
                                </TableCell>
                                <TableCell
                                    label={t('layout.tables.performanceOfIndividualAssets.currencyImpact')}
                                    align="right"
                                >
                                    <ValueAmount
                                        amount={row.currencyImpactValue}
                                        currency={row.currency}
                                        locked={isFree}
                                        fontWeight={FontWeightEnum.SemiBold}
                                        nullValue={0}
                                        hidable
                                    />
                                    <Text size="small">
                                        <ValuePercents
                                            amount={row.currencyImpactPercentage}
                                            locked={isFree}
                                            fontWeight={FontWeightEnum.Medium}
                                            nullValue={0}
                                            inParentheses
                                        />
                                    </Text>
                                </TableCell>
                                <TableCell
                                    label={t('layout.tables.performanceOfIndividualAssets.dividendsValue')}
                                    align="right"
                                >
                                    <ValueAmount
                                        amount={row.dividendsValue}
                                        currency={row.currency}
                                        fontWeight={FontWeightEnum.SemiBold}
                                        nullValue={0}
                                        hidable
                                    />
                                </TableCell>
                                <TableCell
                                    label={t('layout.tables.performanceOfIndividualAssets.profit')}
                                    align="right"
                                >
                                    <ValueAmount
                                        amount={row.profitValue}
                                        currency={row.currency}
                                        fontWeight={FontWeightEnum.SemiBold}
                                        nullValue={0}
                                        hidable
                                    />
                                    <Text size="small">
                                        <ValuePercents
                                            amount={row.profitPercentage}
                                            fontWeight={FontWeightEnum.Medium}
                                            nullValue={0}
                                            inParentheses
                                        />
                                    </Text>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                {unrealizedAllocationsOverview ? (
                    <AllocationsUnrealizedOverview mobileBreakUnder="lg" overview={unrealizedAllocationsOverview} />
                ) : (
                    <></>
                )}
            </Table>
        </Wrapper>
    );
});
