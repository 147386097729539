import styled, { css } from 'styled-components';

export const Overflow = styled.div``;

export const Wrapper = styled.div``;

export const Inner = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isFullScreen'].includes(prop),
})<{
    isFullScreen: boolean;
}>`
    ${({ isFullScreen }) => css`
        height: ${isFullScreen ? 'calc(100vh - 12rem - 4rem - 1.8rem)' : '35rem'};
        position: relative;
    `}
`;
