import styled, { css } from 'styled-components';
import { ColorEnum } from '@app/common';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['variant'].includes(prop),
})<{
    variant?: ColorEnum | null;
}>`
    ${({ theme, variant }) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: ${theme.radius.large};
        background-color: ${variant ? theme.palette.color[variant].main : theme.palette.color.blue.main};
        box-shadow: ${theme.shadow.large};
        height: 100%;
        min-height: 37.6rem;

        @media ${theme.breakpoints.lg.min} {
            padding: 2.2rem;
        }
    `};
`;

export const TitleLabelWrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        inset: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: ${theme.gap.vsmall};
    `};
`;

export const ImageWrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        width: 100%;
        max-width: 100%;
        height: inherit;
        min-height: 8rem;

        @media ${theme.breakpoints.vl.min} {
            background-position: 0 -3rem;
            min-height: 5rem;
        }
    `};
`;

export const TitleWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        gap: ${theme.gap.xsmall};
        align-items: center;
        padding: 2.2rem;
        padding-bottom: 0;
        text-transform: uppercase;
        color: ${theme.palette.common.textMuted};
        font-size: ${theme.text.size.vsmall.fontSize};
        line-height: ${theme.text.size.vsmall.fontSize};
        font-weight: 700;
    `};
`;

export const IconTitleWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        padding: 0 2.2rem;
        align-items: center;
        color: ${theme.palette.color.white.main};
        gap: ${theme.gap.vsmall};
        font-size: ${theme.text.size.vlarge.fontSize};
        line-height: ${theme.text.size.vlarge.fontSize};
        font-weight: 700;
    `};
`;

export const Title = styled.div`
    ${({ theme }) => css`
        color: #fafcff;
        opacity: 0.6;
        font-size: ${theme.text.size.vsmall.fontSize};
        line-height: ${theme.text.size.vsmall.fontSize};
        font-weight: 700;
    `};
`;
