import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Wrapper } from './skeleton-dashboard-cards-unrealized-profit.styled';
import { ValueDescription } from '../../../../card/value-description/value-description';
import Skeleton from '../../../skeleton';
import { IconArrowRightWide } from '../../../../icons/arrow-right-wide';
import { IconButton } from '../../../../icon/button/icon-button';
import { CardTitle } from '../../../../card/title/card-title';
import { Stack } from '../../../../stack/stack';

export const SkeletonDashboardCardsUnrealizedProfit: React.FC = observer(() => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <CardTitle
                title={t('layout.statisticsCard.unrealizedProfit.title')}
                actions={
                    <IconButton
                        icon={<IconArrowRightWide />}
                        variant={'default'}
                        variation={'outlined'}
                        size={'small'}
                    />
                }
            />
            <Stack direction="vertical">
                <Skeleton height={1.9} width={6.5} />
                <ValueDescription
                    padding={'none'}
                    value={<Skeleton height={2.4} width={15} />}
                    description={<Skeleton height={1.3} width={16} />}
                    valueColor={'default'}
                />
            </Stack>
        </Wrapper>
    );
});
