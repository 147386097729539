import { darken } from 'polished';
import styled, { css } from 'styled-components';

function getContrastColor(bgColor: string) {
    const r = parseInt(bgColor.slice(1, 3), 16);
    const g = parseInt(bgColor.slice(3, 5), 16);
    const b = parseInt(bgColor.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness > 130 ? 'black' : '#ffffff';
}

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['color'].includes(prop),
})<{
    color: string;
}>`
    ${({ theme, color }) => css`
        background: ${darken(0.2, color)};
        color: ${getContrastColor(darken(0.2, color))};
        padding: ${theme.gap.small};
        border-radius: ${theme.radius.medium};
        display: flex;
        flex-direction: column;
        box-shadow: ${theme.shadow.large};

        &:after {
            top: 100%;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            pointer-events: none;
            border-color: rgba(0, 0, 0, 0);
            border-top-color: ${darken(0.2, color)};
            border-width: 1rem;
        }
    `}
`;

export const Row = styled.div`
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
`;

export const Name = styled.div`
    margin-bottom: 1.5rem;
    max-width: 20rem;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.2rem;
    word-break: break-word;
`;

export const Code = styled.span`
    font-weight: 400;
    font-size: 1.2rem;
    opacity: 0.7;
`;

export const Price = styled.div`
    font-weight: 400;
    font-size: 1.6rem;
`;

export const Label = styled.div`
    font-weight: 700;
    font-size: 1.6rem;
`;

export const Value = styled.div`
    font-weight: 400;
    font-size: 1.6rem;
`;

export const Percents = styled.div`
    font-weight: 700;
    font-size: 2rem;
`;
