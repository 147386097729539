import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Content, Label, Value, Wrapper } from './skeleton-dashboard-cards-invested-value.styled';
import Skeleton from '../../../skeleton';
import { IconArrowRightWide } from '../../../../icons/arrow-right-wide';
import { IconButton } from '../../../../icon/button/icon-button';
import { CardTitle } from '../../../../card/title/card-title';
import { ValueDescription } from '../../../../card/value-description/value-description';

export const SkeletonDashboardCardsInvestedValue: React.FC = observer(() => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <CardTitle
                title={t('layout.statisticsCard.investedValue.title')}
                actions={
                    <IconButton
                        icon={<IconArrowRightWide />}
                        variant={'default'}
                        variation={'outlined'}
                        size={'small'}
                    />
                }
            />
            <Content>
                <>
                    <ValueDescription
                        padding={'none'}
                        value={<Skeleton height={2} />}
                        description={<Skeleton height={1.3} />}
                    />

                    <br />

                    <Label>{t('layout.statisticsCard.investedValue.investmentPeriod')}</Label>

                    <Value>
                        <Skeleton height={1.4} />
                    </Value>

                    <br />

                    <Label>{t('layout.statisticsCard.investedValue.monthlyAverage')}</Label>
                    <Value>
                        <Skeleton height={1.4} />
                    </Value>
                </>
            </Content>
        </Wrapper>
    );
});
