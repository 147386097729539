import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { FontWeightEnum, SubscriptionEnum, TextVariantColorEnum } from '@app/common';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { Table } from '../../../table/table';
import { TableCell } from '../../../table/cell/table-cell';
import { TableRow } from '../../../table/row/table-row';
import { TableBody } from '../../../table/body/table-body';
import { Text } from '../../../text/text';
import { NoData } from '../../../no-data/no-data';
import { TableHead } from '../../../table/head/table-head';
import { Symbol } from '../../../symbol/symbol';
import { routes } from '../../../../../config/routes';
import { Wrapper } from './allocations-realized-table.styled';
import { SortButton } from '../../../sorting/sort-button';
import { Stack } from '../../../stack/stack';
import { ValueAmount } from '../../../value/amount/value-amount';
import { ValuePercents } from '../../../value/percents/value-precents';
import { SkeletonRealizedAllocationsTable } from '../../../skeleton/dashboard/allocations/realized-allocations/skeleton-realized-allocations-table';
import { SymbolTypeEnum } from '../../../../../defs/api';
import { AllocationsPopoverSort } from '../popover/sort/allocations-popover-sort';
import { Popover } from '../../../popover/popover';
import { AllocationsRealizedOverview } from './allocations-realized-overview';
import { ValueQuantity } from '../../../value/quantity/value-quantity';

export const AllocationsRealizedTable: React.FC = observer(() => {
    const { t } = useTranslation();
    const {
        portfolioStore,
        statisticStore: {
            ratiosStore,
            ratiosStore: { realizedAllocations, realizedAllocationsOverview },
        },
        authStore,
    } = useRootStore();

    const user = authStore.userProfile;

    const isFree = user?.subscription === SubscriptionEnum.Free;

    if (ratiosStore.loading || portfolioStore.selectedPortfolio.isRecalculating) {
        return <SkeletonRealizedAllocationsTable />;
    }

    if (realizedAllocations.length === 0) {
        return (
            <Wrapper>
                <NoData>{t('layout.tables.noData')}</NoData>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Table mobileBreakUnder="lg">
                <TableHead>
                    <TableRow>
                        <TableCell width={'48%'}>
                            <Stack direction={'horizontal'} gap={'xsmall'} alignItems={'center'}>
                                {t('layout.tables.performanceOfIndividualAssets.asset')}
                                <SortButton
                                    order={ratiosStore.getFieldSortMethod('weight')}
                                    onSortChange={(newOrder) => {
                                        ratiosStore.getFieldSortMethod('weight');
                                        ratiosStore.setSortModel({
                                            field: 'weight',
                                            method: newOrder,
                                        });
                                    }}
                                />
                            </Stack>
                        </TableCell>
                        <TableCell width={'13%'}>
                            <Stack direction={'horizontal'} gap={'xsmall'} alignItems={'center'}>
                                {t('layout.tables.performanceOfIndividualAssets.qty')}
                                <SortButton
                                    order={ratiosStore.getFieldSortMethod('invested')}
                                    onSortChange={(newOrder) => {
                                        ratiosStore.getFieldSortMethod('invested');
                                        ratiosStore.setSortModel({
                                            field: 'invested',
                                            method: newOrder,
                                        });
                                    }}
                                />
                            </Stack>
                        </TableCell>
                        <TableCell width={'13%'}>
                            <Stack direction={'horizontal'} gap={'xsmall'} alignItems={'center'}>
                                {t('layout.tables.performanceOfIndividualAssets.sellValue')}
                                <SortButton
                                    order={ratiosStore.getFieldSortMethod('value')}
                                    onSortChange={(newOrder) => {
                                        ratiosStore.getFieldSortMethod('value');
                                        ratiosStore.setSortModel({
                                            field: 'value',
                                            method: newOrder,
                                        });
                                    }}
                                />
                            </Stack>
                        </TableCell>
                        <TableCell align="right" width={'13%'}>
                            <Stack direction={'horizontal'} gap={'xsmall'} alignItems={'center'}>
                                {t('layout.tables.performanceOfIndividualAssets.currencyImpact')}
                                <SortButton
                                    order={ratiosStore.getFieldSortMethod('currencyImpactValue')}
                                    onSortChange={(newOrder) => {
                                        ratiosStore.getFieldSortMethod('currencyImpactValue');
                                        ratiosStore.setSortModel({
                                            field: 'currencyImpactValue',
                                            method: newOrder,
                                        });
                                    }}
                                />
                            </Stack>
                        </TableCell>
                        <TableCell align="right" width={'13%'}>
                            <Stack direction={'horizontal'} gap={'xsmall'} alignItems={'center'}>
                                {t('layout.tables.performanceOfIndividualAssets.profit')}
                                <Popover position="top-right" content={<AllocationsPopoverSort />}>
                                    <SortButton
                                        order={ratiosStore.getFieldSortMethod(ratiosStore.profitOrder)}
                                        onSortChange={(newOrder) => {
                                            ratiosStore.getFieldSortMethod(ratiosStore.profitOrder);
                                            ratiosStore.setSortModel({
                                                field: ratiosStore.profitOrder,
                                                method: newOrder,
                                            });
                                        }}
                                    />
                                </Popover>
                            </Stack>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {realizedAllocations.map((row) => {
                        const singleAmountUnit = row.type === SymbolTypeEnum.Ct ? 'kus' : 'akcie';
                        const multipleAmountUnit = row.type === SymbolTypeEnum.Ct ? 'kusů' : 'akcií';

                        return (
                            <TableRow key={row.symbol}>
                                <TableCell>
                                    <Symbol
                                        symbol={row.symbol}
                                        url={`${routes.app.symbols}/${row.exchangeId}/${row.symbol}`}
                                        type={row.type}
                                        name={
                                            row.type === SymbolTypeEnum.Ct
                                                ? `${row.symbolName} (${row.symbolCurrency})`
                                                : `${row.symbolName}`
                                        }
                                        description={
                                            <ValueQuantity
                                                amount={row.amount}
                                                mode="pure"
                                                suffix={multipleAmountUnit}
                                                inParentheses
                                            />
                                        }
                                        ticker={row.symbol}
                                    />
                                </TableCell>
                                <TableCell label={t('layout.tables.performanceOfIndividualAssets.qty')}>
                                    <ValueAmount
                                        hidable={true}
                                        amount={row.invested}
                                        currency={row.currency}
                                        fontWeight={FontWeightEnum.SemiBold}
                                        nullValue={0}
                                        color={TextVariantColorEnum.Secondary}
                                    />
                                    <Text size="small">
                                        <ValueAmount
                                            hidable={true}
                                            amount={row.averageInvested}
                                            currency={`${row.currency} / ${singleAmountUnit}`}
                                            color={TextVariantColorEnum.Secondary}
                                            nullValue={0}
                                            inParentheses
                                        />
                                    </Text>
                                </TableCell>
                                <TableCell label={t('layout.tables.performanceOfIndividualAssets.sellValue')}>
                                    <ValueAmount
                                        amount={row.value}
                                        hidable={true}
                                        currency={row.currency}
                                        fontWeight={FontWeightEnum.SemiBold}
                                        nullValue={0}
                                        color={TextVariantColorEnum.Secondary}
                                    />
                                    <Text size="small">
                                        <ValueAmount
                                            hidable={true}
                                            amount={row.averageSellUnitPrice}
                                            currency={`${row.currency} / ${singleAmountUnit}`}
                                            color={TextVariantColorEnum.Secondary}
                                            inParentheses
                                        />
                                    </Text>
                                </TableCell>
                                <TableCell
                                    label={t('layout.tables.performanceOfIndividualAssets.currencyImpact')}
                                    align="right"
                                >
                                    <ValueAmount
                                        hidable={true}
                                        amount={row.currencyImpactValue}
                                        currency={row.currency}
                                        locked={isFree}
                                        fontWeight={FontWeightEnum.SemiBold}
                                        nullValue={0}
                                    />
                                    <Text size="small">
                                        <ValuePercents
                                            amount={row.currencyImpactPercentage}
                                            inParentheses
                                            locked={isFree}
                                        />
                                    </Text>
                                </TableCell>
                                <TableCell
                                    label={t('layout.tables.performanceOfIndividualAssets.profit')}
                                    align="right"
                                >
                                    <ValueAmount
                                        hidable={true}
                                        amount={row.profitValue}
                                        currency={row.currency}
                                        fontWeight={FontWeightEnum.SemiBold}
                                        nullValue={0}
                                    />
                                    <Text size="small">
                                        <ValuePercents amount={row.profitPercentage} inParentheses />
                                    </Text>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                {realizedAllocationsOverview ? (
                    <AllocationsRealizedOverview mobileBreakUnder="lg" overview={realizedAllocationsOverview} />
                ) : (
                    <></>
                )}
            </Table>
        </Wrapper>
    );
});
