import React from 'react';
import { observer } from 'mobx-react-lite';
import { BasePieChart } from './base/pie/base-pie-chart';
import { useRootStore } from '../../../providers/root-store-provider';
import { PieChartTypeEnum } from '../../../models/enums/pie-chart-type-enum';

type AssetTypeChartType = {
    isFullScreen?: boolean;
};
export const AssetTypeChart: React.FC<AssetTypeChartType> = observer(({ isFullScreen }: AssetTypeChartType) => {
    const { portfolioStore } = useRootStore();
    const { symbolTypeRatio } = useRootStore().statisticStore.ratiosStore;
    if (!symbolTypeRatio) {
        return null;
    }
    return (
        <BasePieChart
            currency={portfolioStore.selectedPortfolio.portfolioHistoryCurrencyCode}
            data={symbolTypeRatio.data}
            type={PieChartTypeEnum.AssetType}
            isFullScreen={isFullScreen}
        />
    );
});
