import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import 'moment/locale/cs';
import { ResponsiveLine } from '@nivo/line';
import { observer } from 'mobx-react-lite';
import { CartesianMarkerProps, linearGradientDef } from '@nivo/core';
import { useTheme } from 'styled-components';
import { formatDecimal } from '@app/common';
import { TotalValueChartTooltip } from '../elements/total-value-chart-tooltip';
import { Wrapper, Inner, Overflow } from './base-line-chart.styled';
import { InlineLegend } from '../legend/inline/inline-legend';
import { ChartLineColorType, ChartLineColorsType, ChartLineType } from '../../../../../theme/type/chart-type';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { AreaChartDataMobxDto } from '../../../../../models/mobx/dtos/statistic/area-chart-data-mobx-dto';
import { AreaChartMobxDto } from '../../../../../models/mobx/dtos/statistic/area-chart-mobx-dto';
import { FullScreenTrigger } from '../../../symbol-detail/symbol-line-chart/symbol-line-chart.styled';
import { IconZoom } from '../../../icons/zoom';

export const BaseLineChart: React.FC<ChartLineType> = observer(
    ({
        tab,
        chartData,
        unit,
        dataOverride,
        dateFormat,
        labelDateFormat,
        filter,
        onChange,
        markers,
        isFullScreen,
        toggleFullScreen,
    }) => {
        const theme = useTheme();
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);
        const { privateStore } = useRootStore();

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        useEffect(() => {
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);

        const colors: ChartLineColorType[] = [
            {
                color: 'primary',
                type: 'light',
            },
            {
                color: 'secondary',
                type: 'main',
            },
            {
                color: 'negative',
                type: 'main',
            },
            {
                color: 'green',
                type: 'main',
            },
            {
                color: 'bitcoin',
                type: 'main',
            },
        ];

        const graphColors: ChartLineColorsType = {
            totalValue: theme.palette.color[colors[0].color][colors[0].type] as string,
            closeValue: theme.palette.color[colors[0].color][colors[0].type] as string,
            closePercentage: theme.palette.color[colors[0].color][colors[0].type] as string,
            invested: theme.palette.color[colors[1].color][colors[1].type] as string,
            profitLoss: theme.palette.color[colors[1].color][colors[1].type] as string,
            CSPX: theme.palette.color[colors[2].color][colors[2].type] as string,
            CNDX: theme.palette.color[colors[3].color][colors[3].type] as string,
            'BTC-USD': theme.palette.color[colors[4].color][colors[4].type] as string,
        };

        const customChartData: AreaChartMobxDto[] = chartData.map((d: AreaChartMobxDto) => {
            return {
                ...d,
                color: graphColors[d.id as keyof typeof graphColors],
            };
        });

        if (!customChartData[0]) return null;

        const fill: { match: { id: string }; id: string }[] = [];
        let lowestValue = Number(
            Math.min(
                ...customChartData[0].data.map((item: AreaChartDataMobxDto) => {
                    return item.y;
                }),
            ),
        );

        customChartData.forEach((data) => {
            fill.push({ match: { id: data.id }, id: `gradient-${data.id}` });
            if (filter.get(data.id)) {
                const currentLowestValue = Number(
                    Math.min(
                        ...data.data.map((item: AreaChartDataMobxDto) => {
                            return item.y;
                        }),
                    ),
                );

                if (currentLowestValue < lowestValue) {
                    lowestValue = currentLowestValue;
                }
            }
        });

        const markerData: CartesianMarkerProps[] | undefined = useMemo(() => {
            if (markers) {
                return markers.map((marker) => {
                    return {
                        axis: marker.axis,
                        value: marker.value,
                        legend: marker.label,
                        legendOrientation: marker.legendOrientation,
                        legendPosition: marker.legendPosition,
                        lineStyle: {
                            strokeWidth: 2,
                            strokeDasharray: '4 4',
                        },
                        textStyle: { fill: theme.palette.common.textMuted },
                    };
                });
            }
            return undefined;
        }, [markers]);

        const xTickValues = Math.floor(windowWidth / 180);

        const memoBaseLine = useMemo(() => {
            return (
                <ResponsiveLine
                    pointLabelYOffset={0}
                    colors={{ datum: 'color' }}
                    data={dataOverride ? customChartData : customChartData.filter((data) => filter.get(data.id))}
                    margin={{ top: 30, right: 0, bottom: 50, left: 90 }}
                    markers={markerData}
                    useMesh={true}
                    xScale={{ type: 'time', format: '%D.%M.%Y' }}
                    yScale={{
                        type: 'linear',
                        min: 'auto',
                        max: 'auto',
                        stacked: false,
                        reverse: false,
                        nice: true,
                    }}
                    yFormat={(value) => formatDecimal(Number(value))}
                    xFormat={'time:%D.%M.%Y'}
                    areaBaselineValue={lowestValue}
                    tooltip={({ point }) => (
                        <TotalValueChartTooltip
                            label={moment(point.data.x).format(labelDateFormat)}
                            amount={+point.data.y}
                            currency={unit}
                        />
                    )}
                    curve="linear"
                    axisTop={null}
                    enableArea={true}
                    areaOpacity={1}
                    crosshairType="bottom"
                    enableGridX={false}
                    defs={[
                        linearGradientDef('gradient-totalValue', [
                            { offset: 0, color: graphColors.totalValue, opacity: 0.4 },
                            { offset: 100, color: graphColors.totalValue, opacity: 0 },
                        ]),
                        linearGradientDef('gradient-invested', [
                            { offset: 0, color: graphColors.invested, opacity: 0.1 },
                            { offset: 50, color: graphColors.invested, opacity: 0 },
                        ]),
                        linearGradientDef('gradient-profitLoss', [
                            { offset: 0, color: graphColors.profitLoss, opacity: 0.1 },
                            { offset: 50, color: graphColors.profitLoss, opacity: 0 },
                        ]),
                        linearGradientDef('gradient-CSPX', [
                            { offset: 0, color: graphColors.CSPX, opacity: 0.1 },
                            { offset: 50, color: graphColors.CSPX, opacity: 0 },
                        ]),
                        linearGradientDef('gradient-CNDX', [
                            { offset: 0, color: graphColors.CNDX, opacity: 0.1 },
                            { offset: 50, color: graphColors.CNDX, opacity: 0 },
                        ]),
                        linearGradientDef('gradient-BTC-USD', [
                            { offset: 0, color: graphColors['BTC-USD'], opacity: 0.1 },
                            { offset: 50, color: graphColors['BTC-USD'], opacity: 0 },
                        ]),
                        linearGradientDef('gradient-closeValue', [
                            { offset: 0, color: graphColors.closeValue, opacity: 0.4 },
                            { offset: 100, color: graphColors.closeValue, opacity: 0 },
                        ]),
                        linearGradientDef('gradient-closePercentage', [
                            { offset: 0, color: graphColors.closePercentage, opacity: 0.4 },
                            { offset: 100, color: graphColors.closePercentage, opacity: 0 },
                        ]),
                    ]}
                    fill={fill}
                    gridYValues={4}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 10,
                        tickRotation: 0,
                        tickValues: xTickValues,
                        format: (value) => moment(value).locale('cs').format(dateFormat),
                    }}
                    axisLeft={
                        !privateStore.isPrivateModeEnabled
                            ? {
                                  tickSize: 0,
                                  tickPadding: 10,
                                  tickValues: 4,
                                  tickRotation: 0,
                                  format: (value) => `${Number(value).toLocaleString('cs-CZ')} ${unit}`,
                              }
                            : null
                    }
                    pointSize={0}
                    theme={{
                        axis: {
                            ticks: {
                                text: {
                                    fontSize: theme.text.size.vsmall.fontSize,
                                    fill: theme.palette.common.textMuted,
                                },
                            },
                            legend: {
                                text: {
                                    fontSize: theme.text.size.vsmall.fontSize,
                                    fill: theme.palette.common.textMuted,
                                },
                            },
                        },
                        legends: {
                            text: {
                                fontSize: theme.text.size.vsmall.fontSize,
                                fill: theme.palette.common.textMuted,
                            },
                        },
                        grid: {
                            line: {
                                stroke: theme.palette.common.border,
                                strokeWidth: 1,
                            },
                        },
                        markers: {
                            lineColor: theme.palette.common.textMuted,
                        },
                    }}
                />
            );
        }, [dataOverride, customChartData]);

        return (
            <Overflow>
                {tab && onChange && (
                    <InlineLegend tab={tab} data={customChartData} onChange={onChange} filter={filter} />
                )}
                <Wrapper>
                    <Inner isFullScreen={isFullScreen}>
                        {!isFullScreen ? (
                            <FullScreenTrigger onClick={toggleFullScreen}>
                                <IconZoom />
                            </FullScreenTrigger>
                        ) : null}
                        {memoBaseLine}
                    </Inner>
                </Wrapper>
            </Overflow>
        );
    },
);
