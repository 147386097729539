import styled, { css, DefaultTheme } from 'styled-components';
import { BreakpointSizeType } from '../../../theme/type/breakpoints-type';
import { GapSizeType } from '../../../theme/type/gap-type';

const getResponsiveCols = (cols: object, theme: DefaultTheme) => {
    return Object.entries(cols).map(([key, value]: BreakpointSizeType[]) => {
        if (typeof value === 'number') {
            return `
                @media ${theme.breakpoints[key].min} {
                    grid-template-columns: repeat(${value}, minmax(0, 1fr));
                }
            `;
        }

        return `
            @media ${theme.breakpoints[key].min} {
                grid-template-columns: ${value};
            }
        `;
    });
};

export const Row = styled.div.withConfig({
    shouldForwardProp: (prop) => !['cols', 'gap', 'marginBottom'].includes(prop),
})<{
    cols: number | object | string;
    gap?: number;
    marginBottom: GapSizeType;
}>(
    ({ theme, cols, gap, marginBottom }) => css`
        align-items: flex-start;
        display: grid;
        flex-wrap: wrap;
        min-height: 100%;
        gap: ${gap}rem;
        margin-bottom: ${theme.gap[marginBottom]};

        ${typeof cols === 'object' &&
        css`
            ${getResponsiveCols(cols, theme)}
        `}

        ${typeof cols === 'number' &&
        css`
            grid-template-columns: repeat(${cols}, 1fr);
        `}

        ${typeof cols === 'string' &&
        css`
            grid-template-columns: ${cols};
        `}
    `,
);
