import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: ${theme.radius.large};
        background-color: ${theme.palette.color.negative.darken};
        box-shadow: ${theme.shadow.large};
        overflow: hidden;
        padding: 1.6rem;
        height: 100%;

        @media ${theme.breakpoints.lg.min} {
            padding: 2.2rem;
        }

        @media ${theme.breakpoints.md.max}, ${theme.breakpoints.xl.min} {
            background-image: url('/images/card-stats/fees-bg.svg');
            background-position: 100% 100%;
            background-size: auto 10rem;
            background-repeat: no-repeat;
        }
    `};
`;

export const Title = styled.div`
    ${({ theme }) => css`
        font-size: ${theme.text.size.vsmall.fontSize};
        line-height: ${theme.text.size.vsmall.lineHeight};
        color: #fafcff;
        opacity: 0.6;
        text-transform: uppercase;
        font-weight: 700;
    `};
`;
