import styled, { css } from 'styled-components';
import { getOpacity } from '../../../../../../theme/utils/get-rgb';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isFullScreen'].includes(prop),
})<{
    isFullScreen?: boolean;
}>(
    ({ theme, isFullScreen }) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 0.1rem solid ${theme.palette.common.border};
        padding: ${theme.gap.xsmall} 0;
        flex: 1;
        gap: 1rem;

        ${isFullScreen &&
        css`
            @media ${theme.breakpoints.lg.min} {
                min-height: 8rem;
            }
        `}

        &:first-child {
            border-top: unset;
        }
    `,
);

export const Name = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isFullScreen'].includes(prop),
})<{
    isFullScreen?: boolean;
}>(
    ({ theme, isFullScreen }) => css`
        display: flex;
        align-items: center;
        color: ${theme.palette.common.textMuted};
        font-weight: 500;
        font-size: ${theme.text.size.vsmall.fontSize};
        width: 100%; /* Nebo nastav šířku podle potřeby */
        white-space: nowrap; /* Zabrání zalamování textu */
        overflow: hidden; /* Skryje text, který přesahuje */
        text-overflow: ellipsis; /* Přidá tři tečky na konec oříznutého textu */

        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 2rem;

            background: linear-gradient(
                90deg,
                ${getOpacity(theme.palette.background.body, 0)} 0%,
                ${getOpacity(theme.palette.background.body, 1)} 100%
            );
        }

        @media ${theme.breakpoints.xxl.min} {
            font-size: ${theme.text.size.medium.fontSize};
        }

        ${isFullScreen &&
        css`
            @media ${theme.breakpoints.lg.min} {
                font-size: ${theme.text.size.xlarge.fontSize};
            }
        `}
    `,
);

export const Symbol = styled.div.withConfig({
    shouldForwardProp: (prop) => !['color', 'isFree', 'isFullScreen'].includes(prop),
})<{
    color: string;
    isFree: boolean;
    isFullScreen?: boolean;
}>(
    ({ theme, color, isFree, isFullScreen }) => css`
        width: 1.2rem;
        height: 1.2rem;
        min-width: 1.2rem;
        border-radius: 100%;
        background-color: ${color};
        margin-right: 0.5rem;

        ${isFree &&
        css`
            filter: blur(0.3rem);
        `}

        @media ${theme.breakpoints.xxl.min} {
            width: 2rem;
            height: 2rem;
            min-width: 2rem;
            margin-right: 0.8rem;
        }

        ${isFullScreen &&
        css`
            @media ${theme.breakpoints.lg.min} {
                width: 4rem;
                height: 4rem;
                min-width: 4rem;
                margin-right: 2rem;
            }
        `}
    `,
);

export const Value = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isFullScreen'].includes(prop),
})<{
    isFullScreen?: boolean;
}>(
    ({ theme, isFullScreen }) => css`
        color: ${theme.palette.color.secondary.main};
        font-weight: 700;
        font-size: ${theme.text.size.vsmall.fontSize};
        margin-left: auto;

        @media ${theme.breakpoints.xxl.min} {
            font-size: ${theme.text.size.medium.fontSize};
        }

        ${isFullScreen &&
        css`
            @media ${theme.breakpoints.lg.min} {
                font-size: ${theme.text.size.xlarge.fontSize};
            }
        `}
    `,
);
