import styled, { css } from 'styled-components';
import { TextVariantType } from '../../../../theme/type/text-type';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        z-index: 99;
        position: relative;
        min-height: 3.6rem;
        margin-bottom: ${theme.gap.small};
    `};
`;

export const Title = styled.div.withConfig({
    shouldForwardProp: (prop) => !['color'].includes(prop),
})<{
    color?: TextVariantType | 'inherit';
}>`
    ${({ theme, color }) => css`
        font-size: ${theme.text.size.vsmall.fontSize};
        line-height: ${theme.text.size.medium.fontSize};
        opacity: 0.6;
        text-transform: uppercase;
        font-weight: 700;

        ${color === 'inherit' &&
        css`
            color: inherit;
        `}

        ${color !== 'inherit' &&
        css`
            color: ${color ? theme.text.variant[color].text : theme.palette.color.secondary.main};
        `}
    `};
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const ActionsWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        gap: ${theme.gap.vsmall};
        align-items: center;
    `};
`;
