import React from 'react';
import { Responsive } from '../../../responsive/responsive';
import { Grid } from '../../../grid/grid';
import { Slideshow } from '../../../swiper/swiper';
import { GridVertical, SlideshowItem } from '../../../../../blocks/app/dashboard/cards/dashboard-cards.styled';
import { SkeletonDashboardCardsPortfolioValue } from './portfolio-value/skeleton-dashboard-cards-portfolio-value';
import { SkeletonDashboardCardsRealizedProfit } from './realized-profit/skeleton-dashboard-cards-realized-profit';
import { SkeletonDashboardCardsUnrealizedProfit } from './unrealized-profit/skeleton-dashboard-cards-unrealized-profit';
import { SkeletonDashboardCardsAllTime } from './all-time/skeleton-dashboard-cards-all-time';
import { SkeletonDashboardCardsInvestedValue } from './invested-value/skeleton-dashboard-cards-invested-value';
import { SkeletonDashboardCardsCurrencyImpact } from './currency-impact/skeleton-dashboard-cards-currency-impact';
import { SkeletonDashboardCardsFees } from './fees/skeleton-dashboard-cards-fees';
import { SkeletonDashboardCardsDividends } from './dividends/skeleton-dashboard-cards-dividends';

export const SkeletonDashboardCards: React.FC = () => {
    return (
        <>
            <Responsive breakpoint={'md'} type={'min'}>
                <Grid gap={1.6} cols={{ xs: 1, vl: 2 }}>
                    <Grid gap={1.6} cols={{ xs: 1 }}>
                        <Grid gap={1.6} cols={{ xs: 1, md: 2 }}>
                            <SkeletonDashboardCardsPortfolioValue />
                            <GridVertical>
                                <SkeletonDashboardCardsRealizedProfit />
                                <SkeletonDashboardCardsUnrealizedProfit />
                            </GridVertical>
                        </Grid>
                        <SkeletonDashboardCardsAllTime />
                    </Grid>
                    <Grid gap={1.6} cols={{ xs: 1 }}>
                        <SkeletonDashboardCardsDividends />
                        <Grid gap={1.6} cols={{ xs: 1, md: 2 }}>
                            <SkeletonDashboardCardsInvestedValue />
                            <GridVertical>
                                <SkeletonDashboardCardsCurrencyImpact />
                                <SkeletonDashboardCardsFees />
                            </GridVertical>
                        </Grid>
                    </Grid>
                </Grid>
            </Responsive>
            <Responsive breakpoint={'md'} type={'max'}>
                <Slideshow>
                    <SlideshowItem>
                        <SkeletonDashboardCardsPortfolioValue />
                    </SlideshowItem>
                    <SlideshowItem>
                        <SkeletonDashboardCardsInvestedValue />
                    </SlideshowItem>
                    <SlideshowItem>
                        <SkeletonDashboardCardsAllTime />
                    </SlideshowItem>
                    <SlideshowItem>
                        <SkeletonDashboardCardsDividends />
                    </SlideshowItem>
                    <SlideshowItem>
                        <SkeletonDashboardCardsRealizedProfit />
                        <SkeletonDashboardCardsUnrealizedProfit />
                    </SlideshowItem>
                    <SlideshowItem>
                        <SkeletonDashboardCardsCurrencyImpact />
                        <SkeletonDashboardCardsFees />
                    </SlideshowItem>
                </Slideshow>
            </Responsive>
        </>
    );
};
