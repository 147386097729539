import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { FontWeightEnum, formatDecimal } from '@app/common';
import { useRootStore } from '../../../../../../providers/root-store-provider';
import { Table } from '../../../../table/table';
import { TableCell } from '../../../../table/cell/table-cell';
import { TableRow } from '../../../../table/row/table-row';
import { TableBody } from '../../../../table/body/table-body';
import { Text } from '../../../../text/text';
import { NoData } from '../../../../no-data/no-data';
import { Symbol } from '../../../../symbol/symbol';
import { routes } from '../../../../../../config/routes';
import { ValueAmount } from '../../../../value/amount/value-amount';
import { ValuePercents } from '../../../../value/percents/value-precents';
import { SkeletonAllocationsRankingsTable } from '../../../../skeleton/dashboard/allocations/rankings/skeleton-allocations-rankings-table';
import { SymbolTypeEnum } from '../../../../../../defs/api';

export const AllocationsRankingsLosersTable: React.FC = observer(() => {
    const { t } = useTranslation();
    const {
        statisticStore: {
            ratiosStore: {
                rankingsAllocations: { losers },
            },
            ratiosStore,
        },
        portfolioStore,
    } = useRootStore();

    if (ratiosStore.loading || portfolioStore.selectedPortfolio.isRecalculating) {
        return <SkeletonAllocationsRankingsTable />;
    }

    if (losers.length === 0) {
        return <NoData>{t('layout.tables.noData')}</NoData>;
    }

    return (
        <Table mobileBreakUnder="sm" containerMarginBottom="none">
            <TableBody>
                {losers.map((row) => {
                    const multipleAmountUnit = row.type === SymbolTypeEnum.Ct ? 'kusů' : 'akcií';

                    return (
                        <TableRow key={row.symbol}>
                            <TableCell>
                                <Symbol
                                    symbol={row.symbol}
                                    url={`${routes.app.symbols}/${row.exchangeId}/${row.symbol}`}
                                    type={row.type}
                                    name={
                                        row.type === SymbolTypeEnum.Ct
                                            ? `${row.symbolName} (${row.symbolCurrency})`
                                            : `${row.symbolName}`
                                    }
                                    description={`(${formatDecimal(row.amount)} ${multipleAmountUnit})`}
                                    ticker={row.symbol}
                                />
                            </TableCell>
                            <TableCell label={t('layout.tables.performanceOfIndividualAssets.profit')} align="right">
                                <ValueAmount
                                    hidable={true}
                                    amount={row.totalProfitValue}
                                    currency={row.currency}
                                    fontWeight={FontWeightEnum.SemiBold}
                                    nullValue={0}
                                />
                                <Text size="small">
                                    <ValuePercents amount={row.totalProfitPercentage} inParentheses />
                                </Text>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
});
