import React from 'react';
import { Responsive } from '../../../../responsive/responsive';
import { Wrapper } from './skeleton-allocations-rankings-table.styled';
import { SkeletonTable } from '../../../table/skeleton-table';

export const SkeletonAllocationsRankingsTable: React.FC = () => {
    return (
        <Wrapper>
            <Responsive breakpoint={'lg'} type={'min'}>
                <SkeletonTable cols={2} rows={5} />
            </Responsive>
        </Wrapper>
    );
};
