import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ValueDescription } from '../../value-description/value-description';
import { Wrapper } from './card-dashboard-currency-impact.styled';
import { CardUpdated } from '../card-loading/card-updated';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { getColor } from '../../../tables/utils/get-color';
import { ValueAmount } from '../../../value/amount/value-amount';
import { CardTitle } from '../../title/card-title';

export const CardDashboardCurrencyImpact: React.FC = observer(() => {
    const { t } = useTranslation();
    const { statisticStore, authStore, portfolioStore } = useRootStore();

    const portfolioCurrency = portfolioStore.selectedPortfolio.portfolioHistoryCurrencyCode;

    return (
        <CardUpdated>
            <Wrapper>
                <CardTitle
                    title={t('layout.statisticsCard.rateImpact.title')}
                    tooltip={t('layout.statisticsCard.rateImpact.tooltip')}
                    badge={authStore.userHasFreeTariff ? 'Premium' : null}
                />
                <ValueDescription
                    padding={'none'}
                    value={
                        <ValueAmount
                            amount={statisticStore.overviewStore.overview?.rateImpact ?? 0}
                            currency={portfolioCurrency}
                            locked={authStore.userHasFreeTariff}
                            mode="pure"
                            hidable
                        />
                    }
                    valueColor={getColor(statisticStore.overviewStore.overview?.rateImpact ?? 0, 0, false)}
                />
            </Wrapper>
        </CardUpdated>
    );
});
