import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Wrapper } from './skeleton-dashboard-cards-realized-profit.styled';
import { ValueDescription } from '../../../../card/value-description/value-description';
import Skeleton from '../../../skeleton';
import { CardTitle } from '../../../../card/title/card-title';

export const SkeletonDashboardCardsRealizedProfit: React.FC = observer(() => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <CardTitle title={t('layout.statisticsCard.realizedProfit.title')} />
            <ValueDescription
                value={<Skeleton height={2.4} />}
                description={<Skeleton height={1.3} />}
                valueColor={'default'}
                padding={'none'}
            />
        </Wrapper>
    );
});
