import styled, { css } from 'styled-components';

export const SlideshowItem = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: ${theme.gap.vsmall};
        align-items: stretch;
        width: 100%;
        min-height: 100%;
        height: 100%;
    `}
`;

export const GridVertical = styled.div`
    display: flex;
    gap: 1.6rem;
    flex-direction: column;
    height: 100%;
`;
