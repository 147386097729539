import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        border-radius: ${theme.radius.large};
        box-shadow: ${theme.shadow.large};
        background-color: ${theme.card.variant.default.background};
        background-image: url('/images/card-stats/all-time-bg.svg');
        background-position: 102% 300%;
        background-size: auto 90%;
        background-repeat: no-repeat;
        overflow: hidden;
        padding: 1.6rem;
        height: 100%;

        @media ${theme.breakpoints.lg.min} {
            padding: 2.2rem;
        }

        @media ${theme.breakpoints.md.max} {
            background-size: auto 10rem;
            background-position: 102% 110%;
        }
    `};
`;

export const Label = styled.div`
    ${({ theme }) => css`
        font-size: ${theme.text.size.medium.fontSize};
        line-height: ${theme.text.size.vlarge.lineHeight};
    `};
`;

export const Value = styled.div`
    ${({ theme }) => css`
        font-size: ${theme.text.size.large.lineHeight};
        line-height: ${theme.text.size.large.lineHeight};
        font-weight: 900;
        color: ${theme.palette.common.text};
    `};
`;
