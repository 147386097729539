import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { TabEnum } from '../../../../../models/enums/tab-enum';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { SkeletonDashboardProfitChart } from '../../../../../components/app/skeleton/dashboard/protif-chart/skeleton-dashboard-profit-chart';
import { BaseLineChart } from '../../../../../components/app/chart/base/line/base-line-chart';

type DashboardProfitChartPercentageType = {
    toggleFullScreen: () => void;
    isFullScreen: boolean;
};
export const DashboardProfitChartPercentage: React.FC<DashboardProfitChartPercentageType> = observer(
    ({ toggleFullScreen, isFullScreen }: DashboardProfitChartPercentageType) => {
        const rootStore = useRootStore();
        const { statisticStore, portfolioStore } = rootStore;
        const { profitStore } = statisticStore;

        if (profitStore.loading || portfolioStore.selectedPortfolio.isRecalculating) {
            return <SkeletonDashboardProfitChart />;
        }

        return (
            <BaseLineChart
                tab={TabEnum.Percentage}
                dateFormat={statisticStore.chartDateFormat}
                labelDateFormat={process.env.NEXT_PUBLIC_DATE_FORMAT as string}
                chartData={statisticStore.profitStore.percentageProfits}
                unit="%"
                filter={profitStore.filter}
                onChange={(id) => profitStore.filterSelected(id)}
                toggleFullScreen={toggleFullScreen}
                isFullScreen={isFullScreen}
            />
        );
    },
);
