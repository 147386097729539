import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Title, Wrapper } from './skeleton-dashboard-cards-fees.styled';
import { ValueDescription } from '../../../../card/value-description/value-description';
import Skeleton from '../../../skeleton';
import { CardTitle } from '../../../../card/title/card-title';

export const SkeletonDashboardCardsFees: React.FC = observer(() => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <CardTitle
                title={<Title>{t('layout.statisticsCard.fees.title')}</Title>}
                actions={<Skeleton height={2.4} width={4} variant="negative" radius={20} />}
            />
            <ValueDescription
                value={<Skeleton height={2.4} variant="negative" />}
                description={<Skeleton height={1.3} variant="negative" />}
                padding={'none'}
                valueColor={'default'}
                descriptionColor={'default'}
            />
        </Wrapper>
    );
});
