import React from 'react';
import { Responsive } from '../../../../responsive/responsive';
import { SkeletonTable } from '../../../table/skeleton-table';
import { Wrapper } from './skeleton-unrealized-allocations-table.styled';

export const SkeletonUnrealizedAllocationsTable: React.FC = () => {
    return (
        <Wrapper>
            <Responsive breakpoint={'xxl'} type={'min'}>
                <SkeletonTable cols={5} rows={5} />
            </Responsive>
        </Wrapper>
    );
};
