import React from 'react';
import { useTranslation } from 'react-i18next';
import { Responsive } from '../../../responsive/responsive';
import { Grid } from '../../../grid/grid';
import { Slideshow } from '../../../swiper/swiper';
import { Section } from '../../../section/section';
import { GridItem, SectionWrapper } from '../../../../../blocks/app/dashboard/charts/dashboard-charts.styled';
import { SkeletonPieChart } from '../../chart/pie/skeleton-pie-chart/skeleton-pie-chart';
import { DashboardChartsItem } from '../../../../../blocks/app/dashboard/charts/item/dashboard-charts-item';

export const SkeletonDashboardCharts: React.FC = () => {
    const { t } = useTranslation();

    return (
        <SectionWrapper>
            <Section>
                <Responsive breakpoint={'vl'} type={'max'}>
                    <>
                        <Responsive breakpoint={'md'} type={'min'}>
                            <Slideshow>
                                <Grid cols={2}>
                                    <GridItem>
                                        <DashboardChartsItem
                                            title={t('pages.index.graphTitle.assetType')}
                                            chart={<SkeletonPieChart legendItems={2} />}
                                        />
                                    </GridItem>
                                    <GridItem>
                                        <DashboardChartsItem
                                            title={t('pages.index.graphTitle.portfolio')}
                                            chart={<SkeletonPieChart legendItems={2} />}
                                        />
                                    </GridItem>
                                </Grid>
                                <Grid cols={2}>
                                    <GridItem>
                                        <DashboardChartsItem
                                            title={t('pages.index.graphTitle.currency')}
                                            chart={<SkeletonPieChart legendItems={3} />}
                                        />
                                    </GridItem>
                                    <GridItem>
                                        <DashboardChartsItem
                                            title={t('pages.index.graphTitle.country')}
                                            chart={<SkeletonPieChart legendItems={2} />}
                                        />
                                    </GridItem>
                                </Grid>
                                <Grid cols={2}>
                                    <GridItem>
                                        <DashboardChartsItem
                                            title={t('pages.index.graphTitle.sector')}
                                            chart={<SkeletonPieChart legendItems={2} />}
                                        />
                                    </GridItem>
                                    <></>
                                </Grid>
                            </Slideshow>
                        </Responsive>
                        <Responsive breakpoint={'md'} type={'max'}>
                            <Slideshow>
                                <GridItem>
                                    <DashboardChartsItem
                                        title={t('pages.index.graphTitle.assetType')}
                                        chart={<SkeletonPieChart legendItems={2} />}
                                    />
                                </GridItem>
                                <GridItem>
                                    <DashboardChartsItem
                                        title={t('pages.index.graphTitle.portfolio')}
                                        chart={<SkeletonPieChart legendItems={2} />}
                                    />
                                </GridItem>
                                <GridItem>
                                    <DashboardChartsItem
                                        title={t('pages.index.graphTitle.currency')}
                                        chart={<SkeletonPieChart legendItems={3} />}
                                    />
                                </GridItem>
                                <GridItem>
                                    <DashboardChartsItem
                                        title={t('pages.index.graphTitle.country')}
                                        chart={<SkeletonPieChart legendItems={1} />}
                                    />
                                </GridItem>
                                <GridItem>
                                    <DashboardChartsItem
                                        title={t('pages.index.graphTitle.sector')}
                                        chart={<SkeletonPieChart legendItems={1} />}
                                    />
                                </GridItem>
                            </Slideshow>
                        </Responsive>
                    </>
                </Responsive>
                <Responsive breakpoint={'vl'} type={'min'}>
                    <Grid cols={{ xs: 1, md: 2, vl: 3 }}>
                        <Grid cols={{ xs: 1 }}>
                            <GridItem>
                                <DashboardChartsItem
                                    title={t('pages.index.graphTitle.assetType')}
                                    chart={<SkeletonPieChart legendItems={1} />}
                                />
                            </GridItem>
                            <GridItem>
                                <DashboardChartsItem
                                    title={t('pages.index.graphTitle.portfolio')}
                                    chart={<SkeletonPieChart legendItems={3} />}
                                />
                            </GridItem>
                        </Grid>
                        <GridItem>
                            <DashboardChartsItem
                                title={t('pages.index.graphTitle.currency')}
                                chart={<SkeletonPieChart legendItems={2} orientation={'vertical'} />}
                            />
                        </GridItem>
                        <Grid cols={{ xs: 1 }}>
                            <GridItem>
                                <DashboardChartsItem
                                    title={t('pages.index.graphTitle.country')}
                                    chart={<SkeletonPieChart legendItems={1} />}
                                />
                            </GridItem>
                            <GridItem>
                                <DashboardChartsItem
                                    title={t('pages.index.graphTitle.sector')}
                                    chart={<SkeletonPieChart legendItems={1} />}
                                />
                            </GridItem>
                        </Grid>
                    </Grid>
                </Responsive>
            </Section>
        </SectionWrapper>
    );
};
