import React from 'react';
import { observer } from 'mobx-react-lite';
import { Stack } from '../../../../stack/stack';
import Skeleton from '../../../skeleton';
import { Wrapper, Legend, LegendInner, Chart, ChartInner } from '../../../../chart/base/pie/base-pie-chart.styled';

interface SkeletonPieChartProps {
    legendItems: number;
    orientation?: 'vertical' | 'horizontal';
}

export const SkeletonPieChart: React.FC<SkeletonPieChartProps> = observer(
    ({ legendItems = 1, orientation = 'horizontal' }) => {
        return (
            <Wrapper orientation={orientation}>
                <Chart orientation={orientation}>
                    <ChartInner>
                        <Skeleton width={'100%'} height={'100%'} radius={20} />
                    </ChartInner>
                </Chart>
                <Legend>
                    <LegendInner>
                        {Array.from(Array(legendItems).keys()).map((item) => (
                            <Stack
                                key={item}
                                gap={'small'}
                                direction={'horizontal'}
                                fullWidth
                                justifyContent="space-between"
                            >
                                <Skeleton width={9} />
                                <Skeleton width={4} />
                            </Stack>
                        ))}
                    </LegendInner>
                </Legend>
            </Wrapper>
        );
    },
);
