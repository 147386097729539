import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Wrapper } from './card-dashboard-realized-profit.styled';
import { ValueDescription } from '../../value-description/value-description';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { CardUpdated } from '../card-loading/card-updated';
import { ValueAmount } from '../../../value/amount/value-amount';
import { CardTitle } from '../../title/card-title';
import { ValuePercents } from '../../../value/percents/value-precents';

export const CardDashboardRealizedProfit: React.FC = observer(() => {
    const { t } = useTranslation();

    const rootStore = useRootStore();
    const { portfolioStore, statisticStore } = rootStore;
    const { overviewStore } = statisticStore;
    const { overview } = overviewStore;

    const portfolioCurrency = portfolioStore.selectedPortfolio.portfolioHistoryCurrencyCode;

    return (
        <CardUpdated fields={['realizedProfit']}>
            <Wrapper>
                <CardTitle
                    title={t('layout.statisticsCard.realizedProfit.title')}
                    tooltip={t('layout.statisticsCard.realizedProfit.tooltip')}
                />
                {overview && (
                    <ValueDescription
                        value={
                            <ValueAmount
                                amount={overview.realizedProfit}
                                currency={portfolioCurrency}
                                mode="pure"
                                nullValue={0}
                                hidable
                            />
                        }
                        valueColor={overview.realizedProfit > 0 ? 'positive' : 'negative'}
                        padding={'none'}
                        description={
                            <Trans
                                i18nKey="layout.statisticsCard.realizedProfit.ofPortfolioValue"
                                components={{
                                    value: <ValuePercents amount={overview.realizedProfitPercentage} mode="pure" />,
                                }}
                            />
                        }
                    />
                )}
            </Wrapper>
        </CardUpdated>
    );
});
