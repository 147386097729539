import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/root-store-provider';
import { BasePieChart } from './base/pie/base-pie-chart';
import { PieChartTypeEnum } from '../../../models/enums/pie-chart-type-enum';

type AssetDistributionChartType = {
    isFullScreen?: boolean;
};

export const AssetDistributionChart: React.FC<AssetDistributionChartType> = observer(
    ({ isFullScreen }: AssetDistributionChartType) => {
        const { portfolioStore } = useRootStore();
        const { allocationTypeRatio } = useRootStore().statisticStore.ratiosStore;
        if (!allocationTypeRatio) {
            return null;
        }
        return (
            <BasePieChart
                data={allocationTypeRatio.data}
                type={PieChartTypeEnum.AssetType}
                currency={portfolioStore.selectedPortfolio.portfolioHistoryCurrencyCode}
                isFullScreen={isFullScreen}
            />
        );
    },
);
