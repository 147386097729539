import * as React from 'react';

import { Wrapper } from './table-foot.styled';
import { BreakpointSizeType } from '../../../../theme/type/breakpoints-type';

export interface TableFootPropsInterface {
    children: React.ReactElement[] | React.ReactElement;
    mobileBreakUnder?: BreakpointSizeType;
    isScrolled?: boolean;
    isScrollable?: boolean;
    isScrollOnEnd?: boolean;
}

export const TableFoot: React.FC<TableFootPropsInterface> = ({
    children,
    mobileBreakUnder,
    isScrolled,
    isScrollable,
    isScrollOnEnd,
}) => {
    const variant = 'tfoot';

    if (Array.isArray(children)) {
        return (
            <Wrapper>
                {children.map((child, index: number) => {
                    return React.cloneElement(child, {
                        mobileBreakUnder,
                        variant,
                        isScrolled,
                        isScrollable,
                        isScrollOnEnd,
                        key: index,
                    });
                })}
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {React.cloneElement(children, {
                mobileBreakUnder,
                isScrolled,
                isScrollable,
                isScrollOnEnd,
                variant,
            })}
        </Wrapper>
    );
};
