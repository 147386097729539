import React from 'react';
import { PieTooltipProps } from '@nivo/pie';
import { formatDecimal } from '@app/common';
import { Code, Label, Name, Percents, Price, Row, Value, Wrapper } from './tooltip.styled';
import { PieChartDataMobxDto } from '../../../../../../models/mobx/dtos/statistic/pie-chart-data-mobx-dto';

interface IBasePieChartTooltipProps {
    data: React.PropsWithChildren<PieTooltipProps<PieChartDataMobxDto>>;
    currency?: string | null;
    type?: string | null;
}

export const Tooltip: React.FC<IBasePieChartTooltipProps> = ({ currency, data }) => {
    return (
        <Wrapper color={data.datum.color}>
            {data.datum.data.id !== data.datum.data.name && (
                <>
                    <Name>
                        {data.datum.data.name} <Code>({data.datum.data.id})</Code>
                    </Name>
                    <Row>
                        <Percents>{data.datum.formattedValue}</Percents>
                        {data.datum.data.absoluteValue !== 0 && (
                            <Price>
                                ({formatDecimal(data.datum.data.absoluteValue, 1)}&nbsp;{currency})
                            </Price>
                        )}
                    </Row>
                </>
            )}

            {data.datum.data.id === data.datum.data.name && (
                <>
                    <Row>
                        <Label>{data.datum.data.id}</Label>
                        <Value>({data.datum.formattedValue})</Value>
                    </Row>
                </>
            )}
        </Wrapper>
    );
};
