import styled, { css } from 'styled-components';

export const Wrapper = styled.span.withConfig({
    shouldForwardProp: (prop) => !['block'].includes(prop),
})<{
    block: boolean;
}>`
    ${({ block }) => css`
        position: relative;
        display: ${block ? 'block' : 'inline-flex'};
    `}
`;

export const Trigger = styled.span.withConfig({
    shouldForwardProp: (prop) => !['block'].includes(prop),
})<{
    block: boolean;
}>`
    ${({ block }) => css`
        display: ${block ? 'block' : 'inline-flex'};
        align-items: center;
        gap: 1rem;
    `}
`;

export const TriggerChildren = styled.span.withConfig({
    shouldForwardProp: (prop) => !['block'].includes(prop),
})<{
    block: boolean;
}>`
    ${({ block }) => css`
        display: ${block ? 'block' : 'inline-block'};
    `}
`;

export const Content = styled.span.withConfig({
    shouldForwardProp: (prop) => !['position'].includes(prop),
})<{
    position?: 'bottom' | 'top' | 'top-right' | 'bottom-right' | 'bottom-left' | 'top-left';
}>`
    ${({ theme, position }) => css`
        position: absolute;
        transition: opacity ${theme.base.transition};
        z-index: 9999;
        max-width: 30rem;
        text-transform: none;
        padding: 2rem 1.6rem;
        display: flex;

        --top-position: -1.6rem;
        --bottom-position: 1.6rem;
        --horizontal-position: 3.2rem;
        --horizontal-position-negative: -3.2rem;

        ${position === 'top-left' &&
        css`
            transform: translateX(var(--horizontal-position-negative));
        `}

        ${position === 'top-right' &&
        css`
            transform: translateX(var(--horizontal-position));
        `}

        ${position === 'bottom-right' &&
        css`
            transform: translateX(var(--horizontal-position));
        `}

        ${position === 'bottom-left' &&
        css`
            transform: translateX(var(--horizontal-position-negative));
        `}
    `}
`;

export const ContentInner = styled.span.withConfig({
    shouldForwardProp: (prop) => !['position', 'arrowPosition'].includes(prop),
})<{
    position?: 'bottom' | 'top' | 'top-right' | 'bottom-right' | 'bottom-left' | 'top-left';
    arrowPosition?: number;
}>`
    ${({ theme, position, arrowPosition }) => css`
        position: relative;
        width: 100%;
        background: ${theme.palette.background.header};
        box-shadow: ${theme.shadow.large};
        border-radius: 0.8rem;
        text-transform: none;
        padding: 1.6rem;
        font-size: ${theme.text.size.small.fontSize};
        line-height: ${theme.text.size.medium.lineHeight};

        &:after {
            border: 0.8rem solid rgba(0, 0, 0, 0);
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        ${arrowPosition &&
        css`
            --arrow-position: ${arrowPosition}rem;
        `}

        ${!arrowPosition &&
        css`
            --arrow-position: 1.6rem;
        `}

        ${position === 'bottom' &&
        css`
            &:after {
                left: 50%;
                transform: translateX(-50%);
                bottom: 100%;
                border-bottom-color: ${theme.palette.background.header};
            }
        `}

        ${position === 'top' &&
        css`
            &:after {
                top: 100%;
                border-top-color: ${theme.palette.background.header};
                left: 50%;
                transform: translateX(-50%);
            }
        `}

        ${position === 'top-left' &&
        css`
            &:after {
                top: 100%;
                border-top-color: ${theme.palette.background.header};
                left: var(--arrow-position);
            }
        `}

        ${position === 'top-right' &&
        css`
            &:after {
                top: 100%;
                border-top-color: ${theme.palette.background.header};
                right: var(--arrow-position);
            }
        `}

        ${position === 'bottom-right' &&
        css`
            &:after {
                right: var(--arrow-position);
                bottom: 100%;
                border-top-color: rgba(0, 0, 0, 0);
                border-bottom-color: ${theme.palette.background.header};
            }
        `}

        ${position === 'bottom-left' &&
        css`
            &:after {
                left: var(--arrow-position);
                bottom: 100%;
                border-top-color: rgba(0, 0, 0, 0);
                border-bottom-color: ${theme.palette.background.header};
            }
        `}
    `}
`;
