import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { ColorEnum, ThemeEnum } from '@app/common';
import { IconTitleWrapper, TitleLabelWrapper, Wrapper } from './card-dashboard-portfolio-value.styled';
import { ValueDescription } from '../../value-description/value-description';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { formatDate } from '../../../../../models/functions/format-date';
import { CardUpdated } from '../card-loading/card-updated';
import { IconPortfolio } from '../../../icon/portfolio/icon-portfolio';
import { TextVariantType } from '../../../../../theme/type/text-type';
import { ValueAmount } from '../../../value/amount/value-amount';
import { CardTitle } from '../../title/card-title';

export const CardDashboardPortfolioValue: React.FC = observer(() => {
    const { t } = useTranslation();
    const { statisticStore, themeStore } = useRootStore();
    const { overview } = statisticStore.overviewStore;
    const { portfolioStore } = useRootStore();

    const portfolioCurrency = portfolioStore.selectedPortfolio.portfolioHistoryCurrencyCode;

    const cardTextColor = (
        portfolioStore.selectedPortfolio.colorName === ColorEnum.Secondary && themeStore.theme === ThemeEnum.Dark
            ? 'black'
            : 'white'
    ) as TextVariantType;

    return (
        <CardUpdated fields={['portfolioValue', 'lastUpdated']}>
            <Wrapper variant={portfolioStore.selectedPortfolio.colorName}>
                <TitleLabelWrapper>
                    <CardTitle
                        title={t('layout.statisticsCard.portfolioValue.title')}
                        tooltip={t('layout.statisticsCard.portfolioValue.tooltip')}
                        color={cardTextColor}
                    />
                    <IconTitleWrapper color={cardTextColor}>
                        <IconPortfolio
                            type={portfolioStore.selectedPortfolio.iconName}
                            variant={portfolioStore.selectedPortfolio.colorName}
                            size={'vsmall'}
                            dark
                        />
                        {portfolioStore.selectedPortfolio.name}
                    </IconTitleWrapper>
                </TitleLabelWrapper>
                {overview && (
                    <ValueDescription
                        value={
                            <ValueAmount
                                amount={overview.portfolioValue}
                                currency={portfolioCurrency}
                                mode="pure"
                                hidable
                            />
                        }
                        description={t('layout.statisticsCard.portfolioValue.recalculatedOn', {
                            value: formatDate(overview.lastUpdated, true),
                        })}
                        padding={'none'}
                        valueColor={cardTextColor}
                        descriptionColor={cardTextColor}
                    />
                )}
            </Wrapper>
        </CardUpdated>
    );
});
