import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        border-radius: ${theme.radius.large};
        box-shadow: ${theme.shadow.large};
        background-color: ${theme.card.variant.default.background};
        overflow: hidden;
        padding: 1.6rem;
        height: 100%;

        @media ${theme.breakpoints.lg.min} {
            padding: 2.2rem;
        }

        @media ${theme.breakpoints.md.max} {
            min-height: 13rem;
            background-image: url('/images/card-stats/currency-impact-bg.svg');
            background-position: 100% 100%;
            background-size: 8rem;
            background-repeat: no-repeat;
        }
    `};
`;
