import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Title, Header, Content, FullScreenTrigger } from './dashboard-charts-item.styled';
import { IconZoom } from '../../../../../components/app/icons/zoom';
import { FullScreen } from '../../profit-chart/dashboard-profit-chart.styled';
import { IconClose } from '../../../../../components/app/icons/close';
import { PageHeadline } from '../../../../../components/app/page-headline/page-headline';

type DashboardChartsItem = {
    title: string;
    chart: React.ReactElement;
    badge?: React.ReactNode;
};
export const DashboardChartsItem: React.FC<DashboardChartsItem> = observer(
    ({ title, badge, chart }: DashboardChartsItem) => {
        const [isFullScreen, setIsFullScreen] = useState(false);

        const toggleFullScreen = () => {
            setIsFullScreen(!isFullScreen);
        };

        useEffect(() => {
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === 'Escape' && setIsFullScreen) {
                    setIsFullScreen(false);
                }
            };

            if (isFullScreen) {
                document.addEventListener('keydown', handleKeyDown);
                document.body.style.overflow = 'hidden';
            } else {
                document.removeEventListener('keydown', handleKeyDown);
                document.body.style.overflow = '';
            }
        }, [isFullScreen, setIsFullScreen]);

        const renderFullScreenChart = () => (
            <FullScreen>
                <FullScreenTrigger isFullScreen={isFullScreen} onClick={toggleFullScreen}>
                    <IconClose />
                </FullScreenTrigger>
                <PageHeadline title={title} />

                {React.cloneElement(chart, {
                    isFullScreen,
                    ...chart.props,
                })}
            </FullScreen>
        );

        return (
            <>
                <Header>
                    <Title>
                        {title} {badge}
                    </Title>
                    <FullScreenTrigger onClick={toggleFullScreen}>
                        <IconZoom />
                    </FullScreenTrigger>
                </Header>
                <Content>
                    {React.cloneElement(chart, {
                        isFullScreen,
                        ...chart.props,
                    })}
                </Content>

                {isFullScreen && createPortal(renderFullScreenChart(), document.body)}
            </>
        );
    },
);
