import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Value, Wrapper } from './skeleton-dashboard-cards-all-time.styled';
import { CardUpdated } from '../../../../card/dashboard/card-loading/card-updated';
import Skeleton from '../../../skeleton';
import { CardTitle } from '../../../../card/title/card-title';

export const SkeletonDashboardCardsAllTime: React.FC = observer(() => {
    const { t } = useTranslation();

    return (
        <CardUpdated fields={['totalPortfolioValue']}>
            <Wrapper>
                <div>
                    <CardTitle title={t('layout.statisticsCard.allTime.title')} />
                </div>
                <Value>
                    <Skeleton height={2} />
                </Value>
            </Wrapper>
        </CardUpdated>
    );
});
