import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import {
    Divider,
    Label,
    TitleValue,
    Column,
    Wrapper,
    Row,
    ActionsWrapper,
} from './skeleton-dashboard-cards-dividends.styled';
import { Tooltip } from '../../../../tooltip/tooltip';
import Skeleton from '../../../skeleton';
import { IconHelpFilled } from '../../../../icons/help-filled';
import { IconArrowRightWide } from '../../../../icons/arrow-right-wide';
import { IconButton } from '../../../../icon/button/icon-button';
import { CardTitle } from '../../../../card/title/card-title';

export const SkeletonDashboardCardsDividends: React.FC = observer(() => {
    const { t } = useTranslation();

    const getActions = () => {
        return (
            <ActionsWrapper>
                <IconButton icon={<IconArrowRightWide />} variant={'default'} variation={'outlined'} size={'small'} />
            </ActionsWrapper>
        );
    };

    return (
        <Wrapper>
            <CardTitle title={t('layout.statisticsCard.dividends.title')} actions={getActions()} />
            <Row>
                <Column>
                    <Label>
                        <Label bold>
                            <Skeleton />
                        </Label>
                        {t('layout.statisticsCard.dividends.yield')}
                    </Label>
                    <Label>
                        <Label bold>
                            <Skeleton />
                        </Label>
                        {t('layout.statisticsCard.dividends.yieldOnCost')}
                    </Label>
                </Column>
                <Divider />
                <Column>
                    <Label>
                        {t('layout.statisticsCard.dividends.prediction')}
                        <Tooltip content={t('layout.statisticsCard.dividends.prediction')}>
                            <IconHelpFilled />
                        </Tooltip>
                    </Label>
                    <Label bold>
                        <Skeleton />
                    </Label>
                </Column>
                <Divider />
                <Column>
                    <Label>{t('layout.statisticsCard.dividends.payout')}</Label>
                    <TitleValue>
                        <Skeleton />
                    </TitleValue>
                </Column>
            </Row>
        </Wrapper>
    );
});
