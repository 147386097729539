import React from 'react';
import { observer } from 'mobx-react-lite';
import { BasePieChart } from './base/pie/base-pie-chart';
import { useRootStore } from '../../../providers/root-store-provider';
import { PieChartTypeEnum } from '../../../models/enums/pie-chart-type-enum';

type SectorChartType = {
    isFree?: boolean;
    isFullScreen?: boolean;
};

export const SectorChart: React.FC<SectorChartType> = observer(({ isFree = false, isFullScreen }: SectorChartType) => {
    const { portfolioStore } = useRootStore();
    const { sectorRatio } = useRootStore().statisticStore.ratiosStore;

    if (!sectorRatio) {
        return null;
    }

    return (
        <BasePieChart
            currency={portfolioStore.selectedPortfolio.portfolioHistoryCurrencyCode}
            type={PieChartTypeEnum.Sector}
            data={sectorRatio.data}
            isFree={isFree}
            isFullScreen={isFullScreen}
        />
    );
});
