import styled, { css } from 'styled-components';

export const Title = styled.div`
    ${({ theme }) => css`
        color: ${theme.card.variant.default.title.color};
        text-transform: uppercase;
        font-weight: 700;
        font-size: ${theme.text.size.vsmall.fontSize};
        line-height: ${theme.text.size.vsmall.lineHeight};
        display: flex;
        align-items: center;
        gap: 1rem;
    `}
`;

export const Header = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: ${theme.gap.medium};
        height: unset;
        position: relative;
    `}
`;

export const Content = styled.div`
    flex: 1;
`;

export const FullScreenTrigger = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isFullScreen'].includes(prop),
})<{
    isFullScreen?: boolean;
}>(
    ({ theme, isFullScreen }) => css`
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        z-index: 100;
        cursor: pointer;
        background-color: ${theme.palette.color.secondary.opacity.low};
        transition: all ${theme.base.transition};

        &:hover {
            background-color: ${theme.palette.color.secondary.main};
        }

        i {
            color: ${theme.palette.common.textInverse};
            width: 1.4rem;
            height: 1.4rem;
        }

        ${isFullScreen &&
        css`
            top: 2rem;
            right: 2rem;
            transform: unset;
            width: 4rem;
            height: 4rem;

            i {
                width: 1.6rem;
                height: 1.6rem;
            }
        `}
    `,
);
