import React from 'react';
import { observer } from 'mobx-react-lite';
import { Wrapper } from './base-legend.styled';
import { BaseLegendItem } from './item/base-legend-item';
import { PieChartTypeEnum } from '../../../../../models/enums/pie-chart-type-enum';
import { PieChartDataMobxDto } from '../../../../../models/mobx/dtos/statistic/pie-chart-data-mobx-dto';

export interface BaseLegendProps {
    data: PieChartDataMobxDto[];
    type?: PieChartTypeEnum;
    colors: Array<string>;
    isFree: boolean;
    isFullScreen?: boolean;
}

export const BaseLegend: React.FC<BaseLegendProps> = observer(
    ({ data, type, colors, isFree, isFullScreen }: BaseLegendProps) => {
        return (
            <Wrapper>
                {data.map((item, index) => (
                    <BaseLegendItem
                        key={index}
                        data={item}
                        type={type}
                        color={colors[index]}
                        isFree={isFree}
                        isFullScreen={isFullScreen}
                    />
                ))}
            </Wrapper>
        );
    },
);
