import React from 'react';
import { observer } from 'mobx-react-lite';
import { Responsive } from '../../../../responsive/responsive';
import { SkeletonTable } from '../../../table/skeleton-table';
import { Wrapper } from './skeleton-realized-allocations-table.styled';

export const SkeletonRealizedAllocationsTable: React.FC = observer(() => {
    return (
        <Wrapper>
            <Responsive breakpoint={'lg'} type={'min'}>
                <SkeletonTable cols={5} rows={5} />
            </Responsive>
        </Wrapper>
    );
});
