import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${() => css`
        position: relative;
        width: 100%;
        height: 100%;
        flex: 1;
        min-height: 18rem;
    `}
`;

export const LoadingAnimation = styled.div`
    ${({ theme }) => css`
        @keyframes fadeBackground {
            from {
                opacity: 0.3;
            }
            to {
                opacity: 0;
            }
        }

        position: absolute;
        inset: 0;
        background-color: ${theme.palette.color.note.main};
        animation: fadeBackground 1s ease-in-out;
        animation-iteration-count: 1;
        z-index: 999;
        border-radius: ${theme.radius.large};
    `}
`;
